import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

import LoaderButton from 'src/components/inputs/LoaderButton'

import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import {
  breakpoints,
  DayFrequency,
  Frequency,
  MonthFrequency,
} from 'src/utils/constants'
import { DoneIcon, StepInfo } from 'src/modules/common'
import { AnimatePresence, motion } from 'framer-motion'
import { defaultTransition, fadeVariants } from 'src/utils/animations'

import { Box } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import Player from 'src/models/player'
import {
  createScheduledSession,
  scheduleDialogOpenSelector,
  ScheduleRequest,
  updateDialogVisibility,
} from 'src/store/scheduleSlice'
import Players from './Players'
import Scheduler from './Scheduler'
import { yupResolver } from '@hookform/resolvers/yup'
import { assignToPlayerSchema } from 'src/utils/validationSchemas'
import {
  selectedDrillSelector,
  updateSelectedDrill,
} from 'src/store/drillsSlice'
import { api } from 'src/utils/api'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'
import {
  selectedSessionSelector,
  updateSelectedSession,
} from 'src/store/sessionSlice'

const I18N_KEY = 'CreateDrillDialog'

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(1, 0, 1, 0)};
  `
)

const StyledDialogActions = styled(DialogActions)(
  () => css`
    justify-content: space-between;
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(0, 2)};
    }

    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(0, 5)};
    }
  `
)

export interface FormValues {
  players: Player[]
  session: string
  sessionVersion: number
  startDate: Date
  endDate?: Date
  frequency: Frequency
  repeatsEvery?: number
  weekDays?: number[]
  monthDay?: number
  monthFrequency?: MonthFrequency
  dayFrequency?: DayFrequency
  occurrences?: number
  endSelector?: null | 'on_date' | 'on_occurrence' | 'no_date'
  showMonthDay?: boolean
}

const AssignToPlayerDialog: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [sessionType, setSessionType] = useState('session')

  const dialogOpen = useSelector(scheduleDialogOpenSelector)
  const selectedDrill = useSelector(selectedDrillSelector)
  const selectedSession = useSelector(selectedSessionSelector)

  useEffect(() => {
    if (selectedDrill) {
      setSessionType('drill')
    }
    if (selectedSession) {
      setSessionType(selectedSession.sessionType || 'session')
    }
  }, [selectedDrill, selectedSession])

  const assignButtonText =
    sessionType === 'session'
      ? t(`${I18N_KEY}.assignSchedule`, 'Assign Schedule')
      : t(`${I18N_KEY}.assignDrill`, 'Assign Drill')

  const [isSubmitting, setSubmitting] = useState(false)

  const [formFrequency, setFormFrequency] = useState(Frequency.Once)
  const validationSchema = useMemo(
    () => assignToPlayerSchema(t, formFrequency),
    [t, formFrequency]
  )

  const [currentStep, setCurrentStep] = useState(0)

  const formMethods = useForm({
    shouldUnregister: false,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    context: { currentStep }, // required for validation
    defaultValues: {
      players: [],
      frequency: Frequency.Once,
      repeatsEvery: 1,
      weekDays: [],
      showMonthDay: true,
    },
  })

  const { control, handleSubmit, watch, errors, formState, reset, setValue } =
    formMethods
  const { isValid } = formState

  const players = watch('players')
  const frequency = watch('frequency')
  const weekDays = watch('weekDays')
  const showMonthDay = watch('showMonthDay')

  useEffect(() => {
    setFormFrequency(frequency)
  }, [setFormFrequency, frequency])

  const closeDialog = () => {
    reset()
    setCurrentStep(0)
    dispatch(updateSelectedDrill({ drill: null }))
    dispatch(updateSelectedSession({ session: null }))
    dispatch(updateDialogVisibility({ isOpen: false }))
  }

  const isStepDisabled = (): boolean | undefined => {
    if (currentStep === 0) {
      return isValid && players.length > 0
    }
    return false
  }

  const handleFormSubmission = handleSubmit(
    async ({
      startDate,
      endDate,
      repeatsEvery,
      weekDays,
      occurrences,
      monthDay,
      monthFrequency,
      dayFrequency,
      endSelector,
      showMonthDay,
      ...values
    }: FormValues) => {
      try {
        setSubmitting(true)
        const schedulePayload: ScheduleRequest = {
          ...values,
          startDate,
        }
        if (frequency === Frequency.Once) {
          schedulePayload.endDate = startDate
        }
        if (frequency !== Frequency.Once) {
          schedulePayload.repeatsEvery = repeatsEvery
          if (endSelector === 'on_date') {
            schedulePayload.endDate = endDate
          } else if (endSelector === 'on_occurrence') {
            schedulePayload.occurrences = occurrences
          }

          if (frequency === Frequency.Weekly) {
            schedulePayload.weekDays = weekDays
          }

          if (frequency === Frequency.Monthly) {
            if (showMonthDay) {
              schedulePayload.monthDay = monthDay
            } else {
              schedulePayload.monthFrequency = monthFrequency
              schedulePayload.dayFrequency = dayFrequency
            }
          }
        }
        if (selectedDrill) {
          const response = await api.post('session', {
            name: 'Drill Only Session',
            sections: [
              {
                name: `${Math.floor(startDate.getTime() / 1000)}_${
                  selectedDrill?.uuid
                }_section`,
                drills: [{ uuid: selectedDrill?.uuid }],
              },
            ],
          })
          if (response.status === 201) {
            const session = getSinglePayloadFromResponse(response)
            schedulePayload.session = session.uuid
            schedulePayload.sessionVersion = session.version
          } else {
            console.error('Unable to create session for Drill only Schedule')
          }
        }
        if (selectedSession) {
          schedulePayload.session = selectedSession.uuid
          schedulePayload.sessionVersion = selectedSession.version
        }
        await dispatch(createScheduledSession(schedulePayload))
        dispatch(
          openToast(
            getSuccessToast(
              t(`${I18N_KEY}.scheduleCreated`, 'Schedule Created')
            )
          )
        )
      } catch (error) {
        dispatch(
          openToast(
            getErrorToast(
              t(
                `${I18N_KEY}.createScheduleError`,
                'Error creating Schedule. Please try it later'
              )
            )
          )
        )
      } finally {
        reset()
        setCurrentStep(0)
        setSubmitting(false)
        closeDialog()
      }
    }
  )

  const steps = [
    t(`${I18N_KEY}.step1`, 'Select Players'),
    t(`${I18N_KEY}.step2`, 'Schedule'),
  ]

  const handleStepClick = () => {
    if (currentStep <= 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const generateStepClick = (index: number) => () => {
    switch (index) {
      case 0:
        setCurrentStep(0)
        break
      case 1:
        setCurrentStep(1)
        break
      default:
        setCurrentStep(0)
        break
    }
  }

  const generateStepDisabled = (index: number) => {
    switch (index) {
      case 0:
        return false
      case 1:
        return players.length <= 0
      default:
        return false
    }
  }

  const handleSelectedPlayersChange = (players: Player[]) => {
    setValue('players', players)
  }

  const handleWeekDaysChange = (weekDays: number[]) => {
    setValue('weekDays', weekDays)
  }

  const handleShowMonthChange = (showMonthDay: boolean) => {
    setValue('showMonthDay', showMonthDay)
  }

  return (
    <Dialog maxWidth="lg" open={dialogOpen}>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => closeDialog()}
          style={{ position: 'absolute', top: 8, right: 8, color: 'gray' }}
        >
          <Close />
        </IconButton>
        <Title variant="h4">
          <Trans i18nKey={`${I18N_KEY}.title`}>Assign to Player</Trans>
        </Title>
        <ButtonContainer>
          {steps.map((step, index) => {
            const completed = index < currentStep
            return (
              <StepInfo
                key={index}
                color="primary"
                $completed={completed}
                $selected={index === currentStep}
                onClick={generateStepClick(index)}
                disabled={generateStepDisabled(index)}
              >
                {completed ? <DoneIcon /> : `${index + 1}. `} {step}
              </StepInfo>
            )
          })}
        </ButtonContainer>
        <>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              exit="exit"
              initial="enter"
              animate="visible"
              variants={fadeVariants}
              key={steps[currentStep]}
              transition={defaultTransition}
            >
              <Box mt={4} style={{ minWidth: '860px' }}>
                {currentStep >= 0 && (
                  <section
                    style={{ display: currentStep === 0 ? 'block' : 'none' }}
                  >
                    <Players
                      selectedPlayers={players}
                      onPlayerChange={handleSelectedPlayersChange}
                    />
                  </section>
                )}
                {currentStep >= 1 && (
                  <section
                    style={{ display: currentStep === 1 ? 'block' : 'none' }}
                  >
                    <Scheduler
                      control={control}
                      errors={errors}
                      players={players}
                      frequency={frequency}
                      selectedWeekDays={weekDays}
                      showMonthDay={showMonthDay}
                      selectedDrill={selectedDrill}
                      selectedSession={selectedSession}
                      onPlayerChange={handleSelectedPlayersChange}
                      onWeekDaysChange={handleWeekDaysChange}
                      onShowMonthDayChange={handleShowMonthChange}
                    />
                  </section>
                )}
              </Box>
            </motion.div>
          </AnimatePresence>
        </>
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        <Box style={{ display: 'flex', gap: '1rem' }}>
          <Button onClick={closeDialog}>
            <Typography color="error">
              <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
            </Typography>
          </Button>
        </Box>
        {currentStep === 0 && (
          <LoaderButton
            loading={false}
            disabled={!isStepDisabled()}
            onClick={handleStepClick}
            variant="contained"
            color="primary"
          >
            <Trans i18nKey={`${I18N_KEY}.continueButton`}>Continue</Trans>
            <ArrowForward />
          </LoaderButton>
        )}
        {currentStep === 1 && (
          <LoaderButton
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            onClick={handleFormSubmission}
            variant="contained"
            color="primary"
          >
            <Typography style={{ fontWeight: '500' }}>
              {assignButtonText}
            </Typography>
          </LoaderButton>
        )}
      </StyledDialogActions>
    </Dialog>
  )
}

export default AssignToPlayerDialog
