import styled, { css } from 'styled-components'

import MuiIconButton from '@material-ui/core/IconButton'

import { breakpoints } from 'src/utils/constants'
import { TableCell } from 'src/components/dataDisplay/tableStyles'

export const StyledCell = styled(TableCell)`
  min-width: 130px;
`

export const StyledCellCentered = styled(TableCell)`
  min-width: 130px;
  text-align: center;
`

export const EndCell = styled(TableCell)(
  ({ theme }) => css`
    min-width: 30px;
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      min-width: 30px;
      padding-left: ${theme.spacing(1)}px;
      padding-right: ${theme.spacing(1)}px;
    }
  `
)

export const IconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.xlight};

    svg {
      width: 0.9em;
      height: 0.9em;
      color: ${theme.palette.background.focusBlue};
    }
  `
)
