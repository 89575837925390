import React from 'react'

interface Props {
  width?: number
  height?: number
}

const DrillCompletionIcon: React.FC<Props> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1622 0.600586V12.7087C21.1622 16.9868 16.3378 19.9736 12.0811 23.0006C7.82432 19.9736 3 16.9868 3 12.7087V0.600586H21.1622Z"
        fill="#2549BF"
        fillOpacity="0.87"
      />
      <path
        d="M17.7571 4.00586V11.9825C17.7571 14.8008 14.7419 16.7685 12.0814 18.7626C9.42096 16.7685 6.40576 14.8008 6.40576 11.9825V4.00586H17.7571Z"
        fill="#2549BF"
        fillOpacity="0.87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4599 2.30371V11.983C19.4599 14.0502 18.3426 15.6566 17.1249 16.8822C16.1243 17.8894 14.867 18.819 13.7505 19.6446C13.5279 19.8091 13.311 19.9695 13.1027 20.1256L12.0815 20.8911L11.0603 20.1256C10.852 19.9696 10.6351 19.8091 10.4125 19.6446C9.29599 18.819 8.03872 17.8894 7.03807 16.8822C5.82039 15.6566 4.70312 14.0502 4.70312 11.983V2.30371H19.4599ZM17.7572 4.00641H6.40583V11.983C6.40583 14.5597 8.92613 16.4253 11.3915 18.2502C11.6227 18.4214 11.8534 18.5922 12.0815 18.7632C12.3096 18.5922 12.5403 18.4214 12.7716 18.2502C15.2369 16.4253 17.7572 14.5597 17.7572 11.983V4.00641Z"
        fill="white"
      />
      <mask
        id="mask0_40000077_20165"
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="12"
        height="12"
      >
        <rect x="6" y="4" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_40000077_20165)">
        <path
          d="M10.7749 11.575L15.0124 7.3375C15.1124 7.2375 15.229 7.1875 15.3624 7.1875C15.4957 7.1875 15.6124 7.2375 15.7124 7.3375C15.8124 7.4375 15.8624 7.55625 15.8624 7.69375C15.8624 7.83125 15.8124 7.95 15.7124 8.05L11.1249 12.65C11.0249 12.75 10.9082 12.8 10.7749 12.8C10.6415 12.8 10.5249 12.75 10.4249 12.65L8.27486 10.5C8.17486 10.4 8.12694 10.2812 8.13111 10.1437C8.13528 10.0062 8.18736 9.8875 8.28736 9.7875C8.38736 9.6875 8.50611 9.6375 8.64361 9.6375C8.78111 9.6375 8.89986 9.6875 8.99986 9.7875L10.7749 11.575Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default React.memo(DrillCompletionIcon)
