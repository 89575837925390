import { config } from '../configLoader'

export const DEPLOYMENT_LOCATION = config.REACT_APP_DEPLOYMENT_LOCATION

export enum DeploymentLocation {
  China = 'china',
  Global = 'global',
}

export const isChina = DEPLOYMENT_LOCATION === DeploymentLocation.China

export enum Routes {
  Dashboard = '/',
  PracticeDashboard = '/practice',
  SignIn = '/sign-in',
  SignInProvider = '/sign-in/provider/:providerName',
  Activity = '/activity',
  Reports = '/activity/reports',
  ReportDetail = '/activity/reports/:reportUuid',
  Practices = '/activity/practices',
  PracticeDetail = '/activity/practice/:practiceUuid',
  Rounds = '/activity/rounds',
  RoundDetail = '/activity/round/:roundUuid',
  Insight = '/insight',
  MonthlyReportDetail = '/monthlyReport/:reportUuid',
  Register = '/register',
  RegisterProvider = '/register/provider/:providerName',
  CLPGASignup = '/clpga-signup', // TODO: Temporary for china 1 month experiment
  Settings = '/settings',
  NotFound = '/not-found',
  Categories = '/categories',
  VerifyEmail = '/verify-email',
  ResetPassword = '/reset-password',
  UpdateProfile = '/update-profile',
  ForgotPassword = '/forgot-password',
  CourseStrategy = '/activity/strategies',
  CourseStrategyDetail = '/strategy/:courseStrategyUuid',
  ImprovementOpportunities = '/improvementOpportunities/generate',
  // Coach Routes
  CoachPractice = '/practice',
  CoachPracticeDrills = '/practice/drill',
  CoachPracticeDrillDetail = '/practice/drill/:drillUuid',
  CoachPracticeSessions = '/practice/session',
  CoachPlayerDash = '/player/:playerUuid',
  CoachPlayerPracticeDash = '/player/:playerUuid/practice',
  CoachPlayerActivity = '/player/:playerUuid/activity',
  CoachPlayerRounds = '/player/:playerUuid/activity/rounds',
  CoachPlayerPractices = '/player/:playerUuid/activity/practices',
  CoachPlayerReports = '/player/:playerUuid/activity/reports',
  CoachPlayerReportDetail = '/player/:playerUuid/activity/reports/:reportUuid',
  CoachPlayerCategories = '/player/:playerUuid/categories',
  CoachPlayerRoundDetail = '/player/:playerUuid/activity/round/:roundUuid',
  CoachPlayerPracticeDetail = '/player/:playerUuid/activity/practice/:practiceUuid',
  CoachPlayerCourseStrategy = '/player/:playerUuid/activity/strategies',
  CoachPlayerCourseStrategyDetail = '/player/:playerUuid/activity/strategies/:courseStrategyUuid',
  CoachPlayerInsight = '/player/:playerUuid/insight',
  CoachPlayerOpportunities = '/player/:playerUuid/insight/opportunities',
  CoachPlayerPredictionModel = '/player/:playerUuid/insight/predictionModel',
  CoachPlayerStrengths = '/player/:playerUuid/insight/strengths',
  // Partner Routes
  PartnerPlayerRoundDetail = '/partner/player/:playerUuid/round/:roundUuid',
}

export enum CategoryRoutes {
  All = '/all',
  Putt = '/putt',
  Short = '/short',
  Drives = '/drives',
  Approach = '/approach',
}

export enum LoginError {
  default = 'default',
  UserLocked = 'user_locked',
  IncorrectDetails = 'incorrect-details',
}

export enum PlayerSettingsRoutes {
  Profile = '/profile',
  Account = '/account',
  Billing = '/billing',
  // disable for now, will be released later
  // Notifications = '/notifications',
  // Sharing = '/sharing',
  // DataExport = '/data-export'
}

export enum CoachSettingsRoutes {
  Profile = '/profile',
  Account = '/account',
  // disable for now, will be released later
  // Notifications = '/notifications',
}

// Only pass uuid if in coach view
export const floatingActionsRoutes = (uuid?: string) =>
  uuid
    ? [
        `/player/${uuid}`,
        `/player/${uuid}/reports`,
        `/player/${uuid}/activity`,
        `/player/${uuid}/strategy`,
        `/player/${uuid}/categories`,
        `/player/${uuid}/activity/rounds`,
        `/player/${uuid}/activity/practices`,
        `/player/${uuid}/activity/reports`,
        `/player/${uuid}/activity/strategies`,
        `/player/${uuid}/insight`,
        `/player/${uuid}/practice`,
      ]
    : [
        Routes.Activity,
        Routes.Dashboard,
        Routes.CourseStrategy,
        Routes.Categories,
        Routes.Reports,
        Routes.Rounds,
        Routes.Practices,
        Routes.Insight,
        Routes.PracticeDashboard,
      ]

export enum ToastType {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export enum StorageKeys {
  Token = 'token',
  RefreshToken = 'refresh_token',
}

export enum UserType {
  Coach = 'coach',
  Player = 'player',
}

export enum PlayerType {
  Amateur = 'amateur',
  Pro = 'professional',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum Unit {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum UnitLabel {
  Feet = 'ft',
  Metric = 'm',
  Imperial = 'yd',
}

export enum DateFormats {
  DatePicker = 'datePicker',
  Default = 'default',
  MonthDay = 'monthDay',
  Report = 'report',
  GraphLabel = 'graphLabel',
  TableRow = 'tableRow',
  Invoice = 'invoice',
  WeekDayMonthDay = 'weekDayMonthDay',
  DayOfWeek = 'dayOfWeek',
  Tooltip = 'tooltip',
}

export type DateFormatsObject = {
  [k in DateFormats]: string
}

export type DateFormatKeys = keyof DateFormats

export const DateFormat: { [k in Languages]: DateFormatsObject } = {
  en: {
    datePicker: 'dd/MM/yyyy',
    default: 'dd MMM yyyy',
    report: 'do MMMM yyyy',
    monthDay: 'MMMM d',
    graphLabel: 'MMM dd',
    tableRow: 'E, MMM do',
    invoice: 'dd/MM/yyyy',
    weekDayMonthDay: 'EEEE, do MMMM',
    dayOfWeek: 'EEEE',
    tooltip: 'EEE, MMM dd',
  },
  // Note: Some formats are the same as there is a specifc format that all ZH dates must have
  zh: {
    datePicker: 'yyyy/MM/dd',
    default: 'MMM dd yyyy',
    report: 'yyyy MMMM do',
    monthDay: 'MMM do',
    graphLabel: 'MMM do',
    tableRow: 'E, MMM do',
    invoice: 'yyyy/MM/dd',
    weekDayMonthDay: 'EEEE, MMM do',
    dayOfWeek: 'EEEE',
    tooltip: 'EEE, MMM do',
  },
}

export enum TimeFilter {
  LastFive = 'last_five',
  LastTen = 'last_ten',
  LastMonth = 'last_month',
  LastThreeMonths = 'last_three_months',
  LastSixMonths = 'last_six_months',
  LastTwelveMonths = 'last_twelve_months',
  All = 'all',
  Custom = 'custom',
}

export enum PracticeTimeFilter {
  LastMonth = 'last_month',
  LastThreeMonths = 'last_three_months',
  LastSixMonths = 'last_six_months',
  LastTwelveMonths = 'last_twelve_months',
  All = 'all',
  Custom = 'custom',
}

export enum TypeFilter {
  Both = 'both',
  Practice = 'practice',
  Tournament = 'tournament',
}

export enum WhereFilter {
  Both = 'both',
  Outdoor = 'outdoor',
  Simulator = 'simulator',
}

export enum CategoryFilter {
  All = 'all',
  Drives = 'drives',
  Approaches = 'approach',
  ShortGame = 'short',
  Putting = 'putt',
}

export enum GoalFilter {
  All = 'all',
  CompletionBased = 'completion_based',
  CoachDefined = 'coach_defined',
  DynamicPerformance = 'dynamic_performance',
}

export enum AuthorFilter {
  All = 'all',
  System = 'system',
  Coach = 'coach',
}

export enum SummaryErrors {
  NoRoundsEntered = 'No rounds entered',
  NoRoundsForFilters = 'No rounds match the given filters',
}

export const TRIAL_PERIOD_ROUNDS = 5
export const TRIAL_PERIOD_COURSE_STRATEGIES = 2

export const DECIMAL_PLACES = 2

export const PAGINATION_LIMIT = 10

export const COACH_PAGINATION_LIMIT = 8

export const DAY_IN_MILLISECONDS = 86400000

export const NO_PROGRESS_LABEL = 'NC'

export const HEADER_HEIGHT = 74

export const COACH_VIEW_HEADER_HEIGHT = 116

export const WEBSITE_LINK = 'https://www.drawmorecircles.com'
export const RESOURCES_LINK = 'https://help.drawmorecircles.com/'

export enum TemplateLink {
  Articles = 'https://help.drawmorecircles.com/en/articles/7921343-feature-overview-course-strategy',
  Practice = 'https://help.drawmorecircles.com/en/articles/5555562-practice-activities-template',
  ShotCollection = 'https://help.drawmorecircles.com/en/articles/5427106-shot-collection',
}

export enum WebsiteRoutes {
  PrivacyTerms = '/privacy-terms',
  PracticeActivityTemplate = '/resources/practice-activity-template',
}

export enum Languages {
  English = 'en',
  Chinese = 'zh',
}

export const DefaultLanguage = isChina ? Languages.Chinese : Languages.English

export enum ErrorNames {
  IncorrectPassword = 'incorrect password',
}

export const REPORT_OWNER_ERROR = 'Report not created by user'

export enum Greeting {
  Morning = 'morning',
  Evening = 'evening',
  Afternoon = 'afternoon',
}

export const UPGRADE_QUERY_PARAM_NAME = 'upgrade'

export enum FeatureGateErrors {
  PremiumFeatureError = 'Premium Feature',
}

export const PASSWORD_RESET_TOKEN_PARAM_NAME = 'token'

export const breakpoints = {
  xxs: 321,
  mobile: 900,
  intercom: 450,
}

export enum WeChatTransactionStatus {
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
}

// TODO: Remove once confirmed to be working fine
export const DISPLAY_PREDICTION =
  config.REACT_APP_ENABLE_PREDICTIVE_ANALYSIS === 'true'

export enum MembershipLevel {
  Basic = 'basic',
  Premium = 'premium',
  CourseStrategy = 'courseStrategy',
}

export enum Frequency {
  Once = 'once',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum MonthFrequency {
  First = 'first',
  Second = 'second',
  Third = 'third',
  Fourth = 'fourth',
  Last = 'last',
}

export enum DayFrequency {
  Day = 'day',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}
