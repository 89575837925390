import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { SessionSchedule } from 'src/utils/golfConstants'
import { PAGINATION_LIMIT } from 'src/utils/constants'

interface InitialState {
  isLoading: boolean
  schedules: SessionSchedule[]
  totalSchedules: number
  selectedSchedule?: SessionSchedule | null
  isOpen: boolean
}

type LoadingPayload = Pick<InitialState, 'isLoading'>

interface SchedulesPayload {
  schedules: SessionSchedule[]
  totalSchedules: number
}

interface SchedulePayload {
  schedule: SessionSchedule
}

interface DialogVisibility {
  isOpen: boolean
  uuid?: string
}

export type ScheduleRequest = Omit<SessionSchedule, 'uuid'>

const initialState: InitialState = {
  schedules: [],
  totalSchedules: 0,
  isOpen: false,
  isLoading: false,
  selectedSchedule: null,
}

const { actions, reducer } = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { isLoading } = action.payload

      state.isLoading = isLoading
    },
    updateTotalSchedules: (state, action: PayloadAction<{ count: number }>) => {
      const { count } = action.payload

      state.totalSchedules = count
    },
    updateSelectedSchedule: (
      state,
      action: PayloadAction<{ schedule: SessionSchedule }>
    ) => {
      state.selectedSchedule = action.payload.schedule
    },
    updateSelectedScheduleByUuid: (
      state,
      action: PayloadAction<{ selectedScheduleUuid: string }>
    ) => {
      const { selectedScheduleUuid } = action.payload

      state.selectedSchedule =
        state.schedules.find(({ uuid }) => uuid === selectedScheduleUuid) ||
        null
    },
    updateSchedules: (state, action: PayloadAction<SchedulesPayload>) => {
      const { schedules, totalSchedules } = action.payload

      state.schedules = schedules
      state.isLoading = false
      state.totalSchedules = totalSchedules ?? 0
    },
    updateSchedule: (state, action: PayloadAction<SchedulePayload>) => {
      const { schedule } = action.payload
      const { schedules } = state
      const updatedSchedules = schedules.slice()
      const scheduleIndex = updatedSchedules.findIndex(
        element => element.uuid === schedule.uuid
      )

      if (scheduleIndex < 0) {
        updatedSchedules.push(schedule)
      } else {
        updatedSchedules[scheduleIndex] = schedule
      }

      state.schedules = updatedSchedules
      state.selectedSchedule = schedule
    },
    updateDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedSchedule = state.schedules?.find(item => item.uuid === uuid)

      state.selectedSchedule = selectedSchedule || null
      state.isOpen = isOpen
    },
    reinitialiseDrill: () => ({ ...initialState }),
  },
})

export default reducer
export const {
  updateSchedule,
  updateSchedules,
  updateLoading,
  updateTotalSchedules,
  updateSelectedSchedule,
  updateSelectedScheduleByUuid,
  updateDialogVisibility,
  reinitialiseDrill,
} = actions

// Selectors
export const scheduleSelector = (state: RootState) => state.schedule

export const totalSchedulesSelector = createSelector(
  scheduleSelector,
  schedule => schedule.totalSchedules
)

export const schedulesLoadingSelector = createSelector(
  scheduleSelector,
  schedule => schedule.isLoading
)

export const schedulesSelector = createSelector(
  scheduleSelector,
  schedule => schedule.schedules
)

export const selectedScheduleSelector = createSelector(
  scheduleSelector,
  ({ selectedSchedule }) => selectedSchedule
)

export const scheduleDialogOpenSelector = createSelector(
  scheduleSelector,
  schedule => schedule.isOpen
)

// Action Creators
export const getScheduledSessions =
  (start: number = 0): AppThunk =>
  async dispatch => {
    dispatch(updateLoading({ isLoading: true }))

    const endpoint = 'schedule'
    try {
      const response = await api.get(endpoint, {
        params: {
          count: true,
          start,
          limit: PAGINATION_LIMIT,
        },
      })
      const schedules: SessionSchedule[] = getPayloadFromResponse(response)
      const totalSchedules = response.data.count || schedules.length

      dispatch(updateSchedules({ schedules, totalSchedules }))
      dispatch(updateLoading({ isLoading: false }))
    } catch (error: any) {
      dispatch(updateLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(openToast(getErrorToast('Could not load schedules')))
      }
    }
  }

export const getScheduledSession =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `schedule/${uuid}`
    try {
      const response = await api.get(endpoint)
      const schedule = getSinglePayloadFromResponse(response)
      dispatch(updateSchedule({ schedule }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const createScheduledSession =
  (scheduleData: ScheduleRequest): AppThunk =>
  async dispatch => {
    const endpoint = 'schedule'

    try {
      const response = await api.post(endpoint, scheduleData)
      const schedule = getSinglePayloadFromResponse(response)
      dispatch(updateSelectedSchedule({ schedule }))
      await dispatch(getScheduledSessions())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save schedule')
    }
  }

export const updatedScheduledSession =
  (uuid: string, scheduleData: ScheduleRequest): AppThunk =>
  async dispatch => {
    const endpoint = `schedule/${uuid}`

    try {
      const response = await api.put(endpoint, scheduleData)
      const schedule = getSinglePayloadFromResponse(response)
      dispatch(updateSchedule({ schedule }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not update schedule')
    }
  }

export const deleteScheduledSession =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `schedule/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getScheduledSessions())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

// export const duplicateScheduledSession =
//   (uuid: string): AppThunk =>
//   async dispatch => {
//     const endpoint = `schedule/${uuid}/duplicate`

//     try {
//       await api.post(endpoint)
//       await dispatch(getScheduledSessions())
//     } catch (error: any) {
//       if (isForbiddenOrUnauthorised(error)) {
//         authRequestFail(dispatch)
//       }
//       throw new Error()
//     }
//   }

export const getScheduledSessionCount = (): AppThunk => async dispatch => {
  const endpoint = 'schedule/count'
  try {
    const scheduleCountResponse = await api.get(endpoint)
    const count = getSinglePayloadFromResponse(scheduleCountResponse)?.count
    dispatch(updateTotalSchedules({ count }))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
