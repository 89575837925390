import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box, Checkbox, Typography } from '@material-ui/core'
import MUIAvatar from '@material-ui/core/Avatar'

import { api } from 'src/utils/api'
import { Player } from 'src/models/player'
import { breakpoints } from 'src/utils/constants'
import {
  coachPlayerOverviewSelector,
  getCoachPlayerByUuid,
} from 'src/store/coachOverviewSlice'
import { coachAdminSelector } from 'src/store/coachSlice'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'
import Skeleton from 'src/components/dataDisplay/Skeleton'

const I18N_KEY = 'PlayerCard'

interface Props {
  isSelected: boolean
  playerUuid: string
  isAcceptedPlayer: boolean
  onChange: (player: Player) => void
}

const Card = styled(Box)<{ $isChecked?: boolean }>(
  ({ $isChecked }) =>
    css`
      display: flex;
      gap: 1rem;
      margin-top: 1px;
      padding: 0.75rem;
      border-radius: 0.5rem;
      background-color: ${$isChecked ? '#618ff733' : ''};
      align-items: center;
    `
)

const Avatar = styled(MUIAvatar)(
  ({ theme }) =>
    css`
      width: 39px;
      height: 39px;
      margin-right: 0;
      background-color: ${theme.palette.primary.main};

      ${theme.breakpoints.up(breakpoints.mobile)} {
        margin-right: ${theme.spacing(1.5)}px;
      }
    `
)

const PlayerCard: React.FC<Props> = ({
  isSelected,
  playerUuid,
  isAcceptedPlayer,
  onChange,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [playerForCoach, setPlayerForCoach] = useState<Player>()
  const { isAdmin } = useSelector(coachAdminSelector)

  const { player, benchmarkDetails } = useSelector(
    coachPlayerOverviewSelector(playerUuid)
  )

  useEffect(() => {
    const getPlayer = async () => {
      if (isAcceptedPlayer || isAdmin) {
        const response = await api.get(`overview/player/${playerUuid}`)
        const playerFetched: Player = getSinglePayloadFromResponse(response)
        setPlayerForCoach(playerFetched)
        await dispatch(getCoachPlayerByUuid(playerUuid))
      } else {
        await dispatch(getCoachPlayerByUuid(playerUuid))
        setPlayerForCoach(player)
      }
      setLoading(false)
    }

    if (!playerForCoach) {
      getPlayer()
    }
  }, [playerForCoach, dispatch, playerUuid, isAcceptedPlayer, player, isAdmin])

  const handlePlayerChange = () => {
    if (playerForCoach) {
      onChange(playerForCoach)
    }
  }

  return loading ? (
    <Card>
      <Skeleton width="100%" height={50} variant="rect" animation="wave" />
    </Card>
  ) : (
    <Card $isChecked={isSelected}>
      <Checkbox
        checked={isSelected}
        color="primary"
        onChange={handlePlayerChange}
      />
      {playerForCoach?.profileImageUrl ? (
        <Avatar
          src={playerForCoach.profileImageUrl}
          alt={t(`${I18N_KEY}.userAlt`, 'User profile')}
        />
      ) : (
        <Avatar>
          {playerForCoach?.firstName
            ? playerForCoach.firstName[0].toUpperCase()
            : ''}
        </Avatar>
      )}
      <Typography>
        {playerForCoach
          ? `${playerForCoach.firstName} ${playerForCoach.lastName}`
          : ''}
      </Typography>
      <Typography color="textSecondary">
        {t(`Enums:Benchmark.${benchmarkDetails?.current?.id}`, {
          defaultValue: '',
        })}
      </Typography>
    </Card>
  )
}

export default PlayerCard
