import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Help from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'

interface Props {
  translationKey: string
  translationValue: string
  title: string
}

const HelpIcon = styled(Help)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(0.5)}px;
    color: ${theme.palette.primary.light};
    font-size: ${theme.typography.pxToRem(16)};
  `
)

const HelpTooltip: React.FC<Props> = ({
  translationKey,
  translationValue,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      arrow
      placement="top"
      title={t(`${translationKey}.${translationValue}`, `${title}`) as string}
    >
      <HelpIcon />
    </Tooltip>
  )
}

export default HelpTooltip
