import { Box, FormControl } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import usePracticeFilters from 'src/hooks/usePracticeFilters'
import styled, { css } from 'styled-components'
import Select from 'src/components/inputs/Select'
import { CategoryFilter } from 'src/utils/constants'

import { metricMapping } from './data/MetricMapping'
import { Options } from 'src/components/inputs/FormSelect'
import { Label } from 'src/components/Label'
import HelpTooltip from 'src/components/dataDisplay/HelpTooltip'

const I18N_KEY = 'CreateDrillDialog'

interface Props {
  control: Control
  errors: FieldErrors
}

interface ErrorProps {
  $active: boolean
}

const FlexContainer = styled.div(
  () => css`
    display: flex;
  `
)

const StyledSelect = styled(Select)`
  margin-top: 6px;
`

const StyledContainer = styled(FormControl)`
  position: relative;
`

const ErrorMessage = styled.span<ErrorProps>(
  ({ theme, $active }) => css`
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${$active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${$active ? -24 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const CategorySelection: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()
  const { categoryFilterOptions } = usePracticeFilters()

  const fieldCategoryError = errors && errors?.category
  const hasCategoryError = !!fieldCategoryError
  const fieldSubcategoryError = errors && errors?.subcategory
  const hasSubcategoryError = !!fieldSubcategoryError

  const categoryFilterOptionsWithNA = useMemo(
    () => [{ label: 'N/A', value: 'n/a' }, ...categoryFilterOptions],
    [categoryFilterOptions]
  )

  const selectedCategory: string = useWatch({ control, name: 'category' }) ?? ''

  const getSubcategoryOptions = (category: string): Options => {
    const naOption = { label: 'N/A', value: 'n/a' }
    const categoryData = metricMapping[category]
    if (!categoryData) return [naOption]

    const subcategoryKeys = Object.keys(categoryData).filter(
      key => key !== 'metrics'
    )

    const options = subcategoryKeys.map(subcategoryKey => ({
      label: t(`Enums:MetricLabels.${subcategoryKey}`) || subcategoryKey,
      value: subcategoryKey,
    }))

    return [naOption, ...options]
  }

  return (
    <FlexContainer style={{ gap: '1rem' }}>
      <Box width="100%">
        <Box display="flex">
          <Label $hasError={hasCategoryError} htmlFor="category">
            <Trans i18nKey={`${I18N_KEY}.category`}>Category</Trans>
          </Label>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="categoryTooltip"
            title="Strokes Gained Category for Tracking Drill Performance"
          />
        </Box>
        <Controller
          name="category"
          control={control}
          defaultValue="n/a"
          render={({ onChange, ...renderProps }) => (
            <StyledContainer>
              <StyledSelect
                options={categoryFilterOptionsWithNA.filter(
                  category => category.value !== CategoryFilter.All
                )}
                variant="outlined"
                onChange={e => {
                  control.trigger('category')
                  control.setValue('subcategory', 'n/a')
                  control.setValue('metric', '')
                  onChange(e)
                }}
                {...renderProps}
                fullWidth
              />
              <ErrorMessage $active={hasCategoryError}>
                {fieldCategoryError?.message}
              </ErrorMessage>
            </StyledContainer>
          )}
        />
      </Box>
      <Box width="100%">
        <Box display="flex">
          <Label $hasError={hasSubcategoryError} htmlFor="subcategory">
            <Trans i18nKey={`${I18N_KEY}.subcategory`}>Sub-Category</Trans>
          </Label>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="subcategoryTooltip"
            title="Strokes Gained Sub-Category for Tracking Drill Performance"
          />
        </Box>
        <Controller
          name="subcategory"
          control={control}
          defaultValue="n/a"
          render={({ onChange, ...renderProps }) => (
            <StyledContainer>
              <StyledSelect
                disabled={selectedCategory === 'n/a'}
                options={getSubcategoryOptions(selectedCategory)}
                variant="outlined"
                onChange={e => {
                  control.trigger('subcategory')
                  control.setValue('metric', '')
                  control.trigger('metric')
                  onChange(e)
                }}
                {...renderProps}
                fullWidth
              />
              <ErrorMessage $active={hasSubcategoryError}>
                {fieldSubcategoryError?.message}
              </ErrorMessage>
            </StyledContainer>
          )}
        />
      </Box>
    </FlexContainer>
  )
}

export default CategorySelection
