import { Box } from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'
import SearchTextField from 'src/components/inputs/SearchTextField'
import { CoachInvitations } from 'src/models/coach-player-overview'
import {
  coachPlayersSelector,
  getCoachPlayers,
} from 'src/store/coachOverviewSlice'
import { Player } from 'src/models/player'
import PlayerCard from './PlayerCard'

const I18N_KEY = 'AssignPlayers'

interface Props {
  selectedPlayers: Player[]
  onPlayerChange: (players: Player[]) => void
}

const Players: React.FC<Props> = ({ selectedPlayers, onPlayerChange }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [searchInput, setSearchInput] = useState('')
  const handleChangeSearchInput = (value: string = '') => {
    if (value !== searchInput) {
      // dispatch(requestInProgress())
      setSearchInput(value)
    }
  }
  const onChangeSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeSearchInput(e.target.value)
  }

  useDebounce(
    () => {
      dispatch(getCoachPlayers(0, searchInput))
    },
    500,
    [searchInput, dispatch]
  )

  const playersOverviewItems = useSelector(coachPlayersSelector)
  const acceptedPlayers: CoachInvitations[] = Object.keys(playersOverviewItems)
    .map(uuid => {
      const player: any = playersOverviewItems[uuid]
      return {
        email: player.email,
        uuid: uuid,
        inviteUuid: player.inviteUuid,
        firstName: player.firstName,
        lastName: player.lastName,
        accepted: player.accepted,
      }
    })
    .filter(player => player.email !== undefined && player.accepted)

  const togglePlayerSelection = (player: Player) => {
    onPlayerChange(
      selectedPlayers.some(p => p.uuid === player.uuid)
        ? selectedPlayers.filter(p => p.uuid !== player.uuid)
        : [...selectedPlayers, player]
    )
  }

  return (
    <Box>
      <SearchTextField
        placeholder={t(`${I18N_KEY}.searchPlayers`, 'Search Players')}
        cleanAriaLabel={t(`${I18N_KEY}.clearSearch`, 'Clear search')}
        onCleanSearch={() => handleChangeSearchInput()}
        onChange={onChangeSearch}
        value={searchInput}
      />
      <Box height="21rem" overflow="auto">
        {acceptedPlayers &&
          acceptedPlayers.map((player: CoachInvitations) => (
            <PlayerCard
              key={player.uuid}
              isSelected={selectedPlayers.some(p => p.uuid === player.uuid)}
              playerUuid={player.uuid || ''}
              isAcceptedPlayer={player.accepted}
              onChange={togglePlayerSelection}
            />
          ))}
      </Box>
    </Box>
  )
}

export default Players
