import React from 'react'
import { CoachDrill } from 'src/utils/golfConstants'
import DrillDetails from './DrillDetails'

// const I18N_KEY = 'DrillSummary'

interface Props {
  drill: CoachDrill
}

const DrillSummary: React.FC<Props> = ({ drill }) => {
  return (
    <>
      <DrillDetails drill={drill} />
    </>
  )
}

export default DrillSummary
