import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { CoachSession } from 'src/utils/golfConstants'
import { PAGINATION_LIMIT } from 'src/utils/constants'
import { practiceFiltersSelector } from './practiceSlice'

interface InitialState {
  isLoading: boolean
  sessions: CoachSession[]
  totalSessions: number
  selectedSession: CoachSession | null
  isCreateDialogOpen: boolean
  isDetailsDialogOpen: boolean
}

type LoadingPayload = Pick<InitialState, 'isLoading'>

interface SessionsPayload {
  sessions: CoachSession[]
  totalSessions: number
}

interface SessionPayload {
  session: CoachSession
}

interface DialogVisibility {
  isOpen: boolean
  uuid?: string
}

export type SessionRequest = Omit<CoachSession, 'uuid'>

const initialState: InitialState = {
  sessions: [],
  totalSessions: 0,
  isCreateDialogOpen: false,
  isDetailsDialogOpen: false,
  isLoading: false,
  selectedSession: null,
}

const { actions, reducer } = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { isLoading } = action.payload

      state.isLoading = isLoading
    },
    updateTotalSessions: (state, action: PayloadAction<{ count: number }>) => {
      const { count } = action.payload

      state.totalSessions = count
    },
    updateSelectedSession: (
      state,
      action: PayloadAction<{ session: CoachSession | null }>
    ) => {
      state.selectedSession = action.payload.session
    },
    updateSelectedSessionByUuid: (
      state,
      action: PayloadAction<{ selectedSessionUuid: string }>
    ) => {
      const { selectedSessionUuid } = action.payload

      state.selectedSession =
        state.sessions.find(({ uuid }) => uuid === selectedSessionUuid) || null
    },
    updateSessions: (state, action: PayloadAction<SessionsPayload>) => {
      const { sessions, totalSessions } = action.payload

      state.sessions = sessions
      state.isLoading = false
      state.totalSessions = totalSessions ?? 0
    },
    updateSession: (state, action: PayloadAction<SessionPayload>) => {
      const { session } = action.payload
      const { sessions } = state
      const updatedSessions = sessions.slice()
      const sessionIndex = updatedSessions.findIndex(
        element => element.uuid === session.uuid
      )

      if (sessionIndex < 0) {
        updatedSessions.push(session)
      } else {
        updatedSessions[sessionIndex] = session
      }

      state.sessions = updatedSessions
      state.selectedSession = session
    },
    updateCreateDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedSession = state.sessions?.find(item => item.uuid === uuid)

      state.selectedSession = selectedSession || null
      state.isCreateDialogOpen = isOpen
    },
    updateDetailsDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedSession = state.sessions?.find(item => item.uuid === uuid)

      state.selectedSession = selectedSession || null
      state.isDetailsDialogOpen = isOpen
    },
    reinitialiseSession: () => ({ ...initialState }),
  },
})

export default reducer
export const {
  updateSession,
  updateSessions,
  updateLoading,
  updateTotalSessions,
  updateSelectedSession,
  updateSelectedSessionByUuid,
  updateCreateDialogVisibility,
  updateDetailsDialogVisibility,
  reinitialiseSession,
} = actions

// Selectors
export const sessionSelector = (state: RootState) => state.session

export const totalSessionsSelector = createSelector(
  sessionSelector,
  session => session.totalSessions
)

export const sessionLoadingSelector = createSelector(
  sessionSelector,
  session => session.isLoading
)

export const sessionsSelector = createSelector(
  sessionSelector,
  session => session.sessions
)

export const selectedSessionSelector = createSelector(
  sessionSelector,
  ({ selectedSession }) => selectedSession
)

export const sessionCreateDialogOpenSelector = createSelector(
  sessionSelector,
  session => session.isCreateDialogOpen
)

export const sessionDetailsDialogOpenSelector = createSelector(
  sessionSelector,
  session => session.isDetailsDialogOpen
)

// Action Creators
export const getCoachSessions =
  (start: number = 0): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    dispatch(updateLoading({ isLoading: true }))
    const { authorFilter, searchTermFilter } = practiceFiltersSelector(state)

    const endpoint = 'session'
    try {
      const response = await api.get(endpoint, {
        params: {
          count: true,
          start,
          limit: PAGINATION_LIMIT,
          author: authorFilter,
          search: searchTermFilter,
        },
      })
      const sessions: CoachSession[] = getPayloadFromResponse(response)
      const totalSessions = response.data.count || sessions.length

      dispatch(updateSessions({ sessions, totalSessions }))
      dispatch(updateLoading({ isLoading: false }))
    } catch (error: any) {
      dispatch(updateLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(openToast(getErrorToast('Could not load sessions')))
      }
    }
  }

export const getCoachSession =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `session/${uuid}`
    try {
      const response = await api.get(endpoint)
      const session = getSinglePayloadFromResponse(response)
      dispatch(updateSession({ session }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const createCoachSession =
  (sessionData: SessionRequest): AppThunk =>
  async dispatch => {
    const endpoint = 'session'

    try {
      const response = await api.post(endpoint, sessionData)
      const session = getSinglePayloadFromResponse(response)
      dispatch(updateSelectedSession({ session }))
      await dispatch(getCoachSessions())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save session')
    }
  }

export const updatedCoachSession =
  (uuid: string, sessionData: SessionRequest): AppThunk =>
  async dispatch => {
    const endpoint = `session/${uuid}`

    try {
      const response = await api.put(endpoint, sessionData)
      const session = getSinglePayloadFromResponse(response)
      dispatch(updateSession({ session }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save session')
    }
  }

export const deleteCoachSession =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `session/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getCoachSessions())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

// export const duplicateCoachSession =
//   (uuid: string): AppThunk =>
//   async dispatch => {
//     const endpoint = `session/${uuid}/duplicate`

//     try {
//       await api.post(endpoint)
//       await dispatch(getCoachSessions())
//     } catch (error: any) {
//       if (isForbiddenOrUnauthorised(error)) {
//         authRequestFail(dispatch)
//       }
//       throw new Error()
//     }
//   }

export const getCoachSessionCount = (): AppThunk => async dispatch => {
  const endpoint = 'session/count'
  try {
    const sessionCountResponse = await api.get(endpoint)
    const count = getSinglePayloadFromResponse(sessionCountResponse)?.count
    dispatch(updateTotalSessions({ count }))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
