import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Radio,
  Select,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Player } from 'src/models/player'
import Close from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import styled, { css } from 'styled-components'
import MUIAvatar from '@material-ui/core/Avatar'
import {
  breakpoints,
  DayFrequency,
  Frequency,
  MonthFrequency,
} from 'src/utils/constants'
import { Label } from 'src/components/inputs/FormTextField'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import HookDatePicker from 'src/components/inputs/HookDatePicker'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import { CoachDrill, CoachSession } from 'src/utils/golfConstants'
import TeeGolfBall from 'src/assets/svgComponents/TeeGolfBall'
import { formatTime } from '../util'

const I18N_KEY = 'Scheduler'

interface Props {
  control: Control
  errors: FieldErrors
  players: Player[]
  frequency: Frequency
  selectedWeekDays: number[]
  showMonthDay: boolean
  selectedDrill?: CoachDrill | null
  selectedSession?: CoachSession | null
  onPlayerChange: (players: Player[]) => void
  onWeekDaysChange: (weekDays: number[]) => void
  onShowMonthDayChange: (showMonthDay: boolean) => void
}

const Avatar = styled(MUIAvatar)(
  ({ theme }) =>
    css`
      width: 32px;
      height: 32px;
      margin-right: 0;
      background-color: ${theme.palette.primary.main};

      ${theme.breakpoints.up(breakpoints.mobile)} {
        margin-right: ${theme.spacing(1.5)}px;
      }
    `
)

const AssignableInfo = styled(Box)(
  () => css`
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    padding: 1rem;
  `
)

const PlayerContainer = styled(Box)(
  () => css`
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    width: fit-content;
    border-radius: 2rem;
    padding-left: 0.5rem;
  `
)

const StyledSelect = styled(Select)`
  margin-top: 6px;
`

const StyledContainer = styled(FormControl)`
  position: relative;
`

const WeekDayContainer = styled(FormGroup)(
  () => css`
    display: flex;
    flex-direction: row;
  `
)

const WeekDayCheckbox = styled(Box)<{ $isSelected: boolean }>(
  ({ theme, $isSelected }) => css`
    justify-items: center;
    border: 1px solid ${theme.palette.background.grey};
    border-radius: 0.5rem;
    background-color: ${$isSelected ? '#618ff733' : ''};
    padding: 1rem;

    .MuiFormControlLabel-root {
      margin: 0;
    }
  `
)

const DayText = styled(Typography)<{ $isDisabled?: boolean }>(
  ({ theme, $isDisabled }) => css`
    color: ${$isDisabled ? 'inherit' : theme.palette.text.disabled};
  `
)

const IconContainer = styled.div<{ $draft: boolean }>(
  ({ theme, $draft }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    min-width: 35px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: ${$draft
      ? theme.palette.background.light
      : theme.palette.primary.light};

    svg {
      width: 90%;
      margin-top: ${theme.spacing(0.5)}px;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

const Scheduler: React.FC<Props> = ({
  control,
  errors,
  players,
  selectedWeekDays,
  showMonthDay,
  selectedDrill,
  selectedSession,
  onPlayerChange,
  onWeekDaysChange,
  onShowMonthDayChange,
}) => {
  const { t } = useTranslation()
  const [frequency, setFrequency] = useState(Frequency.Once)
  const [endSelector, setEndSelector] = useState('on_date')
  const [drillCount, setDrillCount] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)

  const handleRemovePlayer = (playerUuid: string) => {
    const updatedPlayers = players.filter(player => player.uuid !== playerUuid)
    onPlayerChange(updatedPlayers)
  }

  const handleWeekDaysChange = (day: number) => {
    const weekDays = selectedWeekDays.includes(day)
      ? selectedWeekDays.filter(value => value !== day)
      : [...selectedWeekDays, day]
    weekDays.sort((a, b) => a - b)
    onWeekDaysChange(weekDays)
  }

  const handleShowMonthDay = (showMonthDay: boolean) => {
    onShowMonthDayChange(showMonthDay)
  }

  const repeatsEveryHelper = (frequency: Frequency): string => {
    switch (frequency) {
      case Frequency.Daily:
        return t(`${I18N_KEY}.repeatEveryDaily`, 'day(s)')
      case Frequency.Weekly:
        return t(`${I18N_KEY}.repeatEveryWeekly`, 'week(s)')
      case Frequency.Monthly:
        return t(`${I18N_KEY}.repeatEveryMonthly`, 'month(s)')
      default:
        return t(`${I18N_KEY}.invalidFrequency`, 'Unknown')
    }
  }

  useEffect(() => {
    if (selectedSession) {
      const { totalDrills, estimatedTime } = selectedSession.sections.reduce(
        (acc, selectedSession) => {
          const drills = Object.values(selectedSession.drills)
          acc.totalDrills += drills.length
          acc.estimatedTime += drills.reduce(
            (sum, drill) => sum + (drill.estimatedTime || 0),
            0
          )
          return acc
        },
        { totalDrills: 0, estimatedTime: 0 }
      )
      setDrillCount(totalDrills)
      setTotalDuration(estimatedTime)
    }
  }, [selectedSession])

  const frequencyOptions = [
    {
      label: t(`Enums:Frequency.${Frequency.Once}`),
      value: Frequency.Once,
    },
    {
      label: t(`Enums:Frequency.${Frequency.Daily}`),
      value: Frequency.Daily,
    },
    {
      label: t(`Enums:Frequency.${Frequency.Weekly}`),
      value: Frequency.Weekly,
    },
    {
      label: t(`Enums:Frequency.${Frequency.Monthly}`),
      value: Frequency.Monthly,
    },
  ]

  const endSelectorOptions = [
    {
      label: t(`${I18N_KEY}.onSelectedDate`, 'on selected date'),
      value: 'on_date',
    },
    {
      label: t(`${I18N_KEY}.afterOccurrences`, 'after'),
      value: 'on_occurrence',
    },
    {
      label: t(`${I18N_KEY}.noEndDate`, 'no end date'),
      value: 'no_date',
    },
  ]

  const weekDayOptions = [
    { label: 'Mon', value: 0 },
    { label: 'Tue', value: 1 },
    { label: 'Wed', value: 2 },
    { label: 'Thu', value: 3 },
    { label: 'Fri', value: 4 },
    { label: 'Sat', value: 5 },
    { label: 'Sun', value: 6 },
  ]

  const monthFrequencyOptions = [
    {
      label: t(`Enums:MonthFrequency.${MonthFrequency.First}`),
      value: MonthFrequency.First,
    },
    {
      label: t(`Enums:MonthFrequency.${MonthFrequency.Second}`),
      value: MonthFrequency.Second,
    },
    {
      label: t(`Enums:MonthFrequency.${MonthFrequency.Third}`),
      value: MonthFrequency.Third,
    },
    {
      label: t(`Enums:MonthFrequency.${MonthFrequency.Fourth}`),
      value: MonthFrequency.Fourth,
    },
    {
      label: t(`Enums:MonthFrequency.${MonthFrequency.Last}`),
      value: MonthFrequency.Last,
    },
  ]

  const dayFrequencyOptions = [
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Day}`),
      value: DayFrequency.Day,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Monday}`),
      value: DayFrequency.Monday,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Tuesday}`),
      value: DayFrequency.Tuesday,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Wednesday}`),
      value: DayFrequency.Wednesday,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Thursday}`),
      value: DayFrequency.Thursday,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Friday}`),
      value: DayFrequency.Friday,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Saturday}`),
      value: DayFrequency.Saturday,
    },
    {
      label: t(`Enums:DayFrequency.${DayFrequency.Sunday}`),
      value: DayFrequency.Sunday,
    },
  ]

  return (
    <Box maxWidth="860px">
      <Box mb={4}>
        <Typography variant="caption" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.assigning`}>Assigning</Trans>
        </Typography>
        <AssignableInfo mt={2}>
          {selectedDrill ? (
            <>
              <Label>{selectedDrill?.activityName}</Label>
              <Box display="flex" style={{ gap: '1rem' }}>
                <Typography color="textSecondary">
                  <Trans i18nKey={`${I18N_KEY}.category`}>Category</Trans>:{' '}
                  {t(`Enums:CategoryType.${selectedDrill?.category}`, {
                    defaultValue: t(`${I18N_KEY}.n/a`),
                  })}
                </Typography>
                <Typography color="textSecondary">|</Typography>
                <Typography color="textSecondary">
                  <Trans i18nKey={`${I18N_KEY}.subcategory`}>
                    Sub-Category
                  </Trans>
                  :{' '}
                  {t(
                    `Enums:MetricLabels.${selectedDrill?.subcategories?.[0]}`,
                    {
                      defaultValue: t(`${I18N_KEY}.n/a`),
                    }
                  )}
                </Typography>
              </Box>
            </>
          ) : null}
          {selectedSession ? (
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <Box>
                  <IconContainer $draft={false}>
                    <TeeGolfBall />
                  </IconContainer>
                </Box>
                <Box display="flex" flexDirection="column" ml={2}>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.session`}>Session</Trans>
                  </Typography>
                  <Typography>{selectedSession?.name}</Typography>
                </Box>
              </Box>
              <Box display="flex" style={{ gap: '1rem' }}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.drills`}>Drills</Trans>
                  </Typography>
                  <Typography>{drillCount}</Typography>
                </Box>
                <Divider orientation="vertical" />
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.duration`}>Est Time</Trans>
                  </Typography>
                  <Typography>
                    {formatTime(
                      totalDuration,
                      t(`${I18N_KEY}.hr`, { defaultValue: 'h' }),
                      t(`${I18N_KEY}.min`, { defaultValue: 'm' })
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : null}
        </AssignableInfo>
      </Box>
      <Box mb={4}>
        <Typography variant="caption" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.assigningTo`}>Assigning To</Trans>
        </Typography>
        <Box
          display="flex"
          style={{ flexWrap: 'wrap', gap: '0.25rem 1rem' }}
          mt={2}
        >
          {players &&
            players.map((player: Player) => (
              <PlayerContainer key={player.uuid}>
                {player?.profileImageUrl ? (
                  <Avatar
                    src={player.profileImageUrl}
                    alt={t(`${I18N_KEY}.userAlt`, 'User profile')}
                  />
                ) : (
                  <Avatar>
                    {player?.firstName
                      ? player.firstName[0].toUpperCase()
                      : 'X'}
                  </Avatar>
                )}
                {player.firstName}{' '}
                <IconButton
                  aria-label="close"
                  onClick={() => handleRemovePlayer(player.uuid)}
                >
                  <Close style={{ fontSize: 'smaller' }} />
                </IconButton>
              </PlayerContainer>
            ))}
        </Box>
      </Box>
      <Divider />
      <Box mt={4} display="flex" style={{ gap: '1rem' }}>
        <Box width="50%">
          <Label variant="caption">
            <Trans i18nKey={`${I18N_KEY}.repeats`}>Repeating Session</Trans>
          </Label>
          <Controller
            name="frequency"
            control={control}
            defaultValue={Frequency.Once}
            render={({ onChange, ...renderProps }) => (
              <StyledContainer>
                <StyledSelect
                  variant="outlined"
                  onChange={e => {
                    setFrequency(e.target.value as Frequency)
                    onChange(e)
                  }}
                  {...renderProps}
                  fullWidth
                >
                  {frequencyOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </StyledContainer>
            )}
          />
        </Box>
        <Box width="50%">
          <Box>
            <HookDatePicker
              name="startDate"
              control={control}
              disableFuture={false}
              label={
                frequency === Frequency.Once
                  ? t(`${I18N_KEY}.completedBy`, 'Completed By')
                  : t(`${I18N_KEY}.startDate`, 'Start Date')
              }
              datePickerProps={{
                openTo: 'year',
                views: ['year', 'month', 'date'],
              }}
            />
          </Box>
        </Box>
      </Box>
      {frequency !== Frequency.Once && (
        <>
          <Box
            mt={4}
            display="flex"
            style={{ gap: '0.5rem', alignItems: 'center' }}
          >
            <Box width="8rem">
              <HookFormTextField
                control={control}
                name="repeatsEvery"
                label={t(`${I18N_KEY}.repeatsEvery`, 'Repeats Every')}
                type="number"
                errors={errors}
              />
            </Box>
            <Typography style={{ marginTop: '1.25rem' }}>
              {repeatsEveryHelper(frequency)}
            </Typography>
          </Box>

          {(frequency === Frequency.Weekly ||
            frequency === Frequency.Monthly) && (
            <Box mt={4}>
              <Label variant="caption">
                <Trans i18nKey={`${I18N_KEY}.repeatsOn`}>Repeats On</Trans>
              </Label>
              {frequency === Frequency.Weekly && (
                <WeekDayContainer style={{ gap: '0.5rem' }}>
                  {weekDayOptions.map(day => (
                    <WeekDayCheckbox
                      key={day.value}
                      mt={1}
                      $isSelected={
                        selectedWeekDays.includes(day.value) || false
                      }
                    >
                      <Label variant="caption">
                        {t(`${I18N_KEY}.${day.label}`, day.label)}
                      </Label>
                      <FormControlLabel
                        label
                        control={
                          <Controller
                            name="weekDays"
                            control={control}
                            render={() => (
                              <Checkbox
                                checked={
                                  selectedWeekDays.includes(day.value) || false
                                }
                                color="primary"
                                onChange={() => handleWeekDaysChange(day.value)}
                              />
                            )}
                          />
                        }
                      />
                    </WeekDayCheckbox>
                  ))}
                </WeekDayContainer>
              )}

              {frequency === Frequency.Monthly && (
                <Box>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box display="flex" style={{ gap: '1rem' }}>
                      <Radio
                        checked={showMonthDay}
                        onClick={() => handleShowMonthDay(true)}
                        color="primary"
                        style={{ width: '2rem' }}
                      />
                      <Box
                        display="flex"
                        style={{
                          gap: '0.5rem',
                          alignItems: 'center',
                          height: '3.5em',
                        }}
                      >
                        <Box width="6rem">
                          <HookFormTextField
                            disabled={!showMonthDay}
                            control={control}
                            name="monthDay"
                            type="number"
                            errors={errors}
                            style={{
                              cursor: showMonthDay ? 'default' : 'none',
                              caretColor: showMonthDay ? 'auto' : 'transparent',
                            }}
                          />
                        </Box>
                        <DayText $isDisabled={showMonthDay}>
                          <Trans i18nKey={`${I18N_KEY}.monthDay`}>day</Trans>
                        </DayText>
                      </Box>
                    </Box>
                    <Box display="flex" style={{ gap: '1rem' }}>
                      <Radio
                        checked={!showMonthDay}
                        onClick={() => handleShowMonthDay(false)}
                        color="primary"
                        style={{ width: '2rem' }}
                      />
                      <Box width="10rem">
                        <Controller
                          name="monthFrequency"
                          control={control}
                          defaultValue={MonthFrequency.First}
                          render={({ onChange, ...renderProps }) => (
                            <StyledContainer>
                              <StyledSelect
                                variant="outlined"
                                onChange={onChange}
                                disabled={showMonthDay}
                                {...renderProps}
                                fullWidth
                              >
                                {monthFrequencyOptions.map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </StyledContainer>
                          )}
                        />
                      </Box>
                      <Box width="12rem">
                        <Controller
                          name="dayFrequency"
                          control={control}
                          defaultValue={DayFrequency.Day}
                          render={({ onChange, ...renderProps }) => (
                            <StyledContainer>
                              <StyledSelect
                                variant="outlined"
                                onChange={onChange}
                                disabled={showMonthDay}
                                {...renderProps}
                                fullWidth
                              >
                                {dayFrequencyOptions.map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </StyledContainer>
                          )}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}

          <Box mt={4} display="flex" style={{ gap: '1rem', alignItems: 'end' }}>
            <Box width="30%">
              <Label variant="caption">
                <Trans i18nKey={`${I18N_KEY}.ends`}>Ends</Trans>
              </Label>
              <Controller
                name="endSelector"
                control={control}
                defaultValue="on_date"
                render={({ onChange, ...renderProps }) => (
                  <StyledContainer>
                    <StyledSelect
                      variant="outlined"
                      onChange={e => {
                        const endValue = e.target.value as string
                        setEndSelector(endValue)
                        if (endValue === 'no_date') {
                          control.setValue('occurrences', null)
                          control.setValue('endDate', null)
                        }
                        if (endValue === 'on_occurrence') {
                          control.setValue('endDate', null)
                        }
                        if (endValue === 'on_date') {
                          control.setValue('endDate', new Date())
                          control.setValue('occurrences', null)
                        }
                        onChange(e)
                      }}
                      {...renderProps}
                      fullWidth
                    >
                      {endSelectorOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </StyledContainer>
                )}
              />
            </Box>
            {endSelector === 'on_date' && (
              <Box width="50%">
                <Box>
                  <HookDatePicker
                    name="endDate"
                    control={control}
                    label={t(`${I18N_KEY}.endDate`, 'End Date')}
                    disableFuture={false}
                    errors={errors}
                    datePickerProps={{
                      openTo: 'year',
                      views: ['year', 'month', 'date'],
                    }}
                  />
                </Box>
              </Box>
            )}
            {endSelector === 'on_occurrence' && (
              <Box
                display="flex"
                style={{ gap: '0.5rem', alignItems: 'center', height: '3.5em' }}
              >
                <Box width="4rem">
                  <HookFormTextField
                    control={control}
                    name="occurrences"
                    type="number"
                    errors={errors}
                  />
                </Box>
                <Typography>
                  <Trans i18nKey={`${I18N_KEY}.occurrences`}>Occurrences</Trans>
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default Scheduler
