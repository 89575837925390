import React, { useEffect } from 'react'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { Box, Typography } from '@material-ui/core'

import { Label } from 'src/components/inputs/FormTextField'
import Select from 'src/components/inputs/Select'
import { Trans, useTranslation } from 'react-i18next'
import CategorySelection from './CategorySelection'
import HookFormTextareaAutosize from 'src/components/inputs/HookFormTextareaAutosize'
import HookToggleButton from 'src/components/inputs/HookToggleButton'
import { ValueGo } from 'src/utils/golfConstants'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import MetricSelection from './MetricSelection'
import HookNumberChanger from 'src/components/inputs/HookNumberChanger'
import { findMetricById } from './data/MetricMapping'
import { CategoryFilter } from 'src/utils/constants'
import useDrillGoalTypeOptions from 'src/hooks/useDrillGoalTypeOptions'
import HelpTooltip from 'src/components/dataDisplay/HelpTooltip'

const I18N_KEY = 'CoachDefined'

interface Props {
  control: Control
  errors: FieldErrors
  // isDirty: boolean
  // isValid: boolean
  // isSubmitting: boolean
}

const StyledSelect = styled(Select)`
  margin-top: 6px;
`

const CoachDefined: React.FC<Props> = ({
  control,
  errors,
  // isDirty,
  // isValid,
}) => {
  const { t } = useTranslation()
  const { puttOnlyGoalOptions, bestToWorstGoalOptions } =
    useDrillGoalTypeOptions()

  const goalSuccessOptions = [
    { label: t(`Enums:ValueGo.${ValueGo.Above}`), value: ValueGo.Above },
    {
      label: t(`Enums:ValueGo.${ValueGo.Below}`),
      value: ValueGo.Below,
    },
  ]

  // const unitOptions = [
  //   {
  //     label: t(`Enums:DrillMeasurement.${DrillMeasurement.Feet}`),
  //     value: DrillMeasurement.Feet,
  //   },
  //   {
  //     label: t(`Enums:DrillMeasurement.${DrillMeasurement.Yards}`),
  //     value: DrillMeasurement.Yards,
  //   },
  //   {
  //     label: t(`Enums:DrillMeasurement.${DrillMeasurement.Meters}`),
  //     value: DrillMeasurement.Meters,
  //   },
  //   {
  //     label: t(`Enums:DrillMeasurement.${DrillMeasurement.MPH}`),
  //     value: DrillMeasurement.MPH,
  //   },
  //   {
  //     label: t(`Enums:DrillMeasurement.${DrillMeasurement.PuttsMade}`),
  //     value: DrillMeasurement.PuttsMade,
  //   },
  // ]

  const goalSelectionOptions = [
    { label: 'Custom', value: 'custom' },
    { label: 'Metric', value: 'metric' },
  ]

  const customOrMetric: string =
    useWatch({ control, name: 'customOrMetric' }) ?? ''
  const selectedCategory: string = useWatch({ control, name: 'category' }) ?? ''
  const selectedSubcategory: string =
    useWatch({ control, name: 'subcategory' }) ?? ''
  const selectedGoalType = control.getValues('goalType') || null
  const selectedMetric: string = useWatch({ control, name: 'metric' }) ?? ''
  const metric = selectedMetric ? findMetricById(selectedMetric) : null

  useEffect(() => {
    if (customOrMetric === 'metric') {
      control.setValue('go', undefined)
    }
    control.trigger('category')
    control.trigger('subcategory')
  }, [control, customOrMetric, selectedSubcategory])

  return (
    <>
      <Box mt={4} mb={5} width="40%">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">
            <Trans i18nKey={`${I18N_KEY}.customVsMetric`}>
              Custom vs. Metric Based
            </Trans>
          </Typography>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="customVsMetricTooltip"
            title="Determines if the drill is based on a Circles metric (e.g. Proximity to Hole: From 100-125 Yards) or custom created by the coach"
          />
        </Box>
        <HookToggleButton
          name="customOrMetric"
          control={control}
          options={goalSelectionOptions}
        />
      </Box>
      <Box mt={4} mb={5} width="100%">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">
            <Trans i18nKey={`${I18N_KEY}.categoryMetricTitle`}>
              Category & Metric
            </Trans>
          </Typography>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="categoryAndMetricTooltip"
            title="Determine the category, sub-category, and metric for the desired drill"
          />
        </Box>
        <Box mt={2}>
          <CategorySelection control={control} errors={errors} />
        </Box>
        {customOrMetric === 'metric' && (
          <Box mt={4} mb={5}>
            <MetricSelection
              control={control}
              errors={errors}
              category={selectedCategory}
              subcategory={selectedSubcategory}
            />
          </Box>
        )}
      </Box>
      <Box mt={4} mb={5} width="100%">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">
            <Trans i18nKey={`${I18N_KEY}.GoalCompletionTitle`}>
              Goal & Completion
            </Trans>
          </Typography>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="goalAndCompletionTooltip"
            title="Describe the drill and set the goal and completion criteria"
          />
        </Box>
        {customOrMetric === 'metric' && (
          <Box mt={2}>
            <Box display="flex">
              <Label
                variant="caption"
                // color={!isValid && isDirty ? 'error' : undefined}
              >
                <Trans i18nKey={`${I18N_KEY}.goalType`}>Goal Type</Trans>
              </Label>
              <HelpTooltip
                translationKey={`${I18N_KEY}`}
                translationValue="goalTypeTooltip"
                title="How should a player record their results, e.g. their best result or the average of their results"
              />
            </Box>
            <Controller
              name="goalType"
              control={control}
              defaultValue=""
              render={({ onChange, ...renderProps }) => (
                <StyledSelect
                  options={
                    selectedCategory === CategoryFilter.Putting
                      ? puttOnlyGoalOptions
                      : bestToWorstGoalOptions
                  }
                  variant="outlined"
                  placeholder="Select your goal type"
                  onChange={onChange}
                  disabled={selectedCategory === 'n/a'}
                  {...renderProps}
                  showDescriptiveText
                  fullWidth
                />
              )}
            />
          </Box>
        )}
        {customOrMetric === 'metric' && selectedGoalType && (
          <Box mt={4} mb={5} width="100%" display="flex" gridGap="1rem">
            <Box mt={4} mb={5} width="50%" display="flex" gridGap="1rem">
              <Box width="50%">
                <HookFormTextField
                  label={t(`${I18N_KEY}.goalTitle`, 'Goal')}
                  control={control}
                  name="goal"
                  type="number"
                  fullWidth
                  errors={errors}
                  toolTipTranslationKey={`${I18N_KEY}`}
                  toolTipTranslationValue="goalTooltip"
                  toolTipTitle="Set the desired goal for the drill"
                />
              </Box>
              <Box width="7rem">
                <Box display="flex">
                  <Label
                    variant="caption"
                    // color={!isValid && isDirty ? 'error' : undefined}
                  >
                    <Trans i18nKey={`${I18N_KEY}.unit`}>Unit</Trans>
                  </Label>
                  <HelpTooltip
                    translationKey={`${I18N_KEY}`}
                    translationValue="unitTooltip"
                    title="What unit will the drill results be measured in"
                  />
                </Box>
                {/* <Controller
                  name="unit"
                  control={control}
                  defaultValue="feet"
                  render={({ onChange, ...renderProps }) => (
                    <StyledSelect
                      options={unitOptions}
                      variant="outlined"
                      onChange={onChange}
                      {...renderProps}
                      fullWidth
                    />
                  )}
                /> */}
                <Box style={{ alignContent: 'center', height: '4rem' }}>
                  <Typography>
                    {t(`Enums:DrillMeasurement.${metric?.unit}`) ?? 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={4} mb={5} width="50%">
              <Box width="50%">
                <HookNumberChanger
                  control={control}
                  name="attempts"
                  errors={errors}
                  defaultValue={10}
                  label={t(`${I18N_KEY}.attemptsTitle`, 'Attempts')}
                  toolTipTranslationKey={`${I18N_KEY}`}
                  toolTipTranslationValue="attemptsTooltip"
                  toolTipTitle="How many attempts will the player take for the drill"
                />
              </Box>
            </Box>
          </Box>
        )}
        {customOrMetric === 'custom' && (
          <>
            <Box mt={4} mb={5} width="100%">
              <HookFormTextareaAutosize
                name="description"
                label={t(
                  `${I18N_KEY}.goalDescription`,
                  'Custom Goal Description'
                )}
                minRows={8}
                placeholder="Write your custom goal description (e.g. Hit putts past the hole, but within 5 feet of the hole)..."
                control={control}
                errors={errors}
                toolTipTranslationKey={`${I18N_KEY}`}
                toolTipTranslationValue="customGoalDescriptionTooltip"
                toolTipTitle="Describe the drill and how the player will track their performance in the drill"
              />
            </Box>
            <Box mt={4} mb={5} style={{ display: 'flex', gap: '1rem' }}>
              <Box style={{ marginTop: '0' }}>
                <HookToggleButton
                  name="go"
                  control={control}
                  options={goalSuccessOptions}
                  label={t(`${I18N_KEY}.go`, 'Goal Success')}
                  toolTipTranslationKey={`${I18N_KEY}`}
                  toolTipTranslationValue="goalSuccessTooltip"
                  toolTipTitle="Is success determined by being above or below the goal? E.g. for driving distance we want to be above our goal, while for proximity to hole we want to be below our goal"
                />
              </Box>
              <HookFormTextField
                style={{ marginTop: '0' }}
                control={control}
                name="goal"
                type="number"
                fullWidth
                placeholder="Write your success metric (e.g. 300ft)..."
                errors={errors}
                label={t(`${I18N_KEY}.goal`, 'Goal')}
                toolTipTranslationKey={`${I18N_KEY}`}
                toolTipTranslationValue="goalTooltip"
                toolTipTitle="Set the desired goal for the drill"
              />
              <Box width="10rem">
                <HookFormTextField
                  style={{ marginTop: '0' }}
                  control={control}
                  name="unit"
                  fullWidth
                  errors={errors}
                  label={t(`${I18N_KEY}.unit`, 'Unit')}
                  toolTipTranslationKey={`${I18N_KEY}`}
                  toolTipTranslationValue="unitTooltip"
                  toolTipTitle="What unit will the drill results be measured in"
                />
              </Box>
              <Box width="10rem">
                <HookNumberChanger
                  style={{ marginTop: '0' }}
                  control={control}
                  name="attempts"
                  errors={errors}
                  defaultValue={10}
                  label={t(`${I18N_KEY}.attemptsTitle`, 'Attempts')}
                  toolTipTranslationKey={`${I18N_KEY}`}
                  toolTipTranslationValue="attemptsTooltip"
                  toolTipTitle="How many attempts will the player take for the drill"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default CoachDefined
