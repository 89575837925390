import { combineReducers } from '@reduxjs/toolkit'

import auth from 'src/store/authSlice'
import user from 'src/store/userSlice'
import coach from 'src/store/coachSlice'
import toast from 'src/store/toastSlice'
import round from 'src/store/roundSlice'
import plan from 'src/store/planSlice'
import plans from 'src/store/plansSlice'
import player from 'src/store/playerSlice'
import summary from 'src/store/summarySlice'
import invoices from 'src/store/invoiceSlice'
import practice from 'src/store/practiceSlice'
import invitation from 'src/store/invitationSlice'
import subscription from 'src/store/subscriptionSlice'
import coachOverview from 'src/store/coachOverviewSlice'
import report from 'src/store/reportSlice'
import courseStrategy from 'src/store/courseStrategySlice'
import notification from 'src/store/notificationSlice'
import io from 'src/store/ioSlice'
import discussionDocument from 'src/store/discussionDocumentSlice'
import forecast from 'src/store/forecastSlice'
import drill from 'src/store/drillsSlice'
import schedule from 'src/store/scheduleSlice'
import session from 'src/store/sessionSlice'

const rootReducer = combineReducers({
  auth,
  user,
  plan,
  plans,
  coach,
  toast,
  round,
  player,
  summary,
  practice,
  invoices,
  subscription,
  coachOverview,
  invitation,
  report,
  courseStrategy,
  notification,
  io,
  discussionDocument,
  forecast,
  drill,
  schedule,
  session,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
