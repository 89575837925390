import { Box, Typography } from '@material-ui/core'
import React from 'react'
import DrillCoachDefinedIcon from 'src/assets/svgComponents/DrillCoachDefinedIcon'
import DrillCompletionIcon from 'src/assets/svgComponents/DrillCompletionIcon'
import DrillDynamicPerformanceIcon from 'src/assets/svgComponents/DrillDynamicPerformanceIcon'
import { Label } from 'src/components/inputs/FormTextField'
import { DrillType } from 'src/utils/golfConstants'

interface Props {
  label: string
  value: string
  formKey?: any
  fullWidth?: boolean
}

const SummaryTextDisplay: React.FC<Props> = ({
  label,
  value,
  formKey,
  fullWidth,
}) => {
  const isDrillType = Object.values(DrillType).includes(formKey)

  return (
    <Box width={fullWidth ? '100%' : '50%'}>
      <Label variant="caption" color="textSecondary">
        {label}
      </Label>
      <Box display="flex" gridGap="0.25rem">
        {isDrillType && (
          <>
            {formKey === DrillType.CoachDefined && <DrillCoachDefinedIcon />}
            {formKey === DrillType.DynamicPerformance && (
              <DrillDynamicPerformanceIcon />
            )}
            {formKey === DrillType.CompletionBased && <DrillCompletionIcon />}
          </>
        )}
        <Typography color={value ? 'inherit' : 'textSecondary'}>
          {value ?? 'undefined'}
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(SummaryTextDisplay)
