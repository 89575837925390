import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { CoachDrill } from 'src/utils/golfConstants'
import { CategoryFilter, PAGINATION_LIMIT } from 'src/utils/constants'
import { practiceFiltersSelector } from './practiceSlice'

interface InitialState {
  isLoading: boolean
  drills: CoachDrill[]
  totalDrills: number
  selectedDrill: CoachDrill | null
  isCreateDialogOpen: boolean
  isDetailsDialogOpen: boolean
}

type LoadingPayload = Pick<InitialState, 'isLoading'>

interface DrillsPayload {
  drills: CoachDrill[]
  totalDrills: number
}

interface DrillPayload {
  drill: CoachDrill
}

interface DialogVisibility {
  isOpen: boolean
  uuid?: string
}

export type DrillRequest = Omit<CoachDrill, 'uuid'>

const initialState: InitialState = {
  drills: [],
  totalDrills: 0,
  isCreateDialogOpen: false,
  isDetailsDialogOpen: false,
  isLoading: false,
  selectedDrill: null,
}

const { actions, reducer } = createSlice({
  name: 'drill',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { isLoading } = action.payload

      state.isLoading = isLoading
    },
    updateTotalDrills: (state, action: PayloadAction<{ count: number }>) => {
      const { count } = action.payload

      state.totalDrills = count
    },
    updateSelectedDrill: (
      state,
      action: PayloadAction<{ drill: CoachDrill | null }>
    ) => {
      state.selectedDrill = action.payload.drill
    },
    updateSelectedDrillByUuid: (
      state,
      action: PayloadAction<{ selectedDrillUuid: string }>
    ) => {
      const { selectedDrillUuid } = action.payload

      state.selectedDrill =
        state.drills.find(({ uuid }) => uuid === selectedDrillUuid) || null
    },
    updateDrills: (state, action: PayloadAction<DrillsPayload>) => {
      const { drills, totalDrills } = action.payload

      state.drills = drills
      state.isLoading = false
      state.totalDrills = totalDrills ?? 0
    },
    updateDrill: (state, action: PayloadAction<DrillPayload>) => {
      const { drill } = action.payload
      const { drills } = state
      const updatedDrills = drills.slice()
      const drillIndex = updatedDrills.findIndex(
        element => element.uuid === drill.uuid
      )

      if (drillIndex < 0) {
        updatedDrills.push(drill)
      } else {
        updatedDrills[drillIndex] = drill
      }

      state.drills = updatedDrills
      state.selectedDrill = drill
    },
    updateCreateDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedDrill = state.drills?.find(item => item.uuid === uuid)

      state.selectedDrill = selectedDrill || null
      state.isCreateDialogOpen = isOpen
    },
    updateDetailsDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedDrill = state.drills?.find(item => item.uuid === uuid)

      state.selectedDrill = selectedDrill || null
      state.isDetailsDialogOpen = isOpen
    },
    reinitialiseDrill: () => ({ ...initialState }),
  },
})

export default reducer
export const {
  updateDrill,
  updateDrills,
  updateLoading,
  updateTotalDrills,
  updateSelectedDrill,
  updateSelectedDrillByUuid,
  updateCreateDialogVisibility,
  updateDetailsDialogVisibility,
  reinitialiseDrill,
} = actions

// Selectors
export const drillSelector = (state: RootState) => state.drill

export const totalDrillsSelector = createSelector(
  drillSelector,
  drill => drill.totalDrills
)

export const drillsLoadingSelector = createSelector(
  drillSelector,
  drill => drill.isLoading
)

export const drillsSelector = createSelector(
  drillSelector,
  drill => drill.drills
)

export const selectedDrillSelector = createSelector(
  drillSelector,
  ({ selectedDrill }) => selectedDrill
)

export const drillCreateDialogOpenSelector = createSelector(
  drillSelector,
  drill => drill.isCreateDialogOpen
)

export const drillDetailsDialogOpenSelector = createSelector(
  drillSelector,
  drill => drill.isDetailsDialogOpen
)

// Action Creators
export const getCoachDrills =
  (start: number = 0): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    dispatch(updateLoading({ isLoading: true }))
    const { categoryFilter, goalFilter, authorFilter, searchTermFilter } =
      practiceFiltersSelector(state)

    const endpoint = 'drill'
    try {
      const response = await api.get(endpoint, {
        params: {
          count: true,
          start,
          limit: PAGINATION_LIMIT,
          // todo: hack - FE expects 'drive', BE expects 'drives'
          category:
            categoryFilter === CategoryFilter.Drives
              ? 'drives'
              : categoryFilter,
          goal: goalFilter,
          author: authorFilter,
          search: searchTermFilter,
        },
      })
      const drills: CoachDrill[] = getPayloadFromResponse(response)
      const totalDrills = response.data.count || drills.length

      dispatch(updateDrills({ drills, totalDrills }))
      dispatch(updateLoading({ isLoading: false }))
    } catch (error: any) {
      dispatch(updateLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(openToast(getErrorToast('Could not load drills')))
      }
    }
  }

export const getCoachDrill =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `drill/${uuid}`
    try {
      const response = await api.get(endpoint)
      const drill = getSinglePayloadFromResponse(response)
      dispatch(updateDrill({ drill }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const createCoachDrill =
  (drillData: DrillRequest): AppThunk =>
  async dispatch => {
    const endpoint = 'drill'

    try {
      const response = await api.post(endpoint, drillData)
      const drill = getSinglePayloadFromResponse(response)
      dispatch(updateSelectedDrill({ drill }))
      await dispatch(getCoachDrills())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save drill')
    }
  }

export const updatedCoachDrill =
  (uuid: string, drillData: DrillRequest): AppThunk =>
  async dispatch => {
    const endpoint = `drill/${uuid}`

    try {
      const response = await api.put(endpoint, drillData)
      const drill = getSinglePayloadFromResponse(response)
      dispatch(updateDrill({ drill }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save drill')
    }
  }

export const deleteCoachDrill =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `drill/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getCoachDrills())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

export const duplicateCoachDrill =
  (uuid: string): AppThunk =>
  async dispatch => {
    const endpoint = `drill/${uuid}/duplicate`

    try {
      await api.post(endpoint)
      await dispatch(getCoachDrills())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

export const getCoachDrillCount = (): AppThunk => async dispatch => {
  const endpoint = 'drill/count'
  try {
    const drillCountResponse = await api.get(endpoint)
    const count = getSinglePayloadFromResponse(drillCountResponse)?.count
    dispatch(updateTotalDrills({ count }))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
