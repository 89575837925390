import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MUISelect, { SelectProps } from '@material-ui/core/Select'

import { Options } from 'src/components/inputs/FormSelect'
import styled from 'styled-components'

type Props = SelectProps & ExtraProps

interface ExtraProps {
  name: string
  options: Options
  showDescriptiveText?: boolean
}

const StyledMenuItem = styled(MenuItem)`
  display: grid;
`

const DescriptionText = styled.div`
  color: #bcbcbc;
  font-size: smaller;
  margin-top: -0.25rem;
`

const Select: React.FC<Props> = ({
  options,
  placeholder,
  showDescriptiveText = false,
  ...props
}) => {
  return (
    <MUISelect
      IconComponent={ExpandMore}
      MenuProps={{
        elevation: 1,
        getContentAnchorEl: null,
      }}
      displayEmpty
      {...props}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          <span style={{ color: '#bcbcbc' }}>{placeholder}</span>
        </MenuItem>
      )}
      {options.map(({ label, description, value, disabled }) => (
        <StyledMenuItem key={value} value={value} disabled={disabled}>
          {label}
          {showDescriptiveText && (
            <DescriptionText>{description}</DescriptionText>
          )}
        </StyledMenuItem>
      ))}
    </MUISelect>
  )
}

export default React.memo(Select)
