import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { DrillType } from 'src/utils/golfConstants'
import DrillCompletionIcon from 'src/assets/svgComponents/DrillCompletionIcon'
import DrillCoachDefinedIcon from 'src/assets/svgComponents/DrillCoachDefinedIcon'
import DrillDynamicPerformanceIcon from 'src/assets/svgComponents/DrillDynamicPerformanceIcon'

interface Props {
  item: {
    title: string
    value: string | number
    key: string
  }
}

const Item = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(3, 3, 3, 0)};
    &:first-child {
      padding-left: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(0, 3, 3, 3)};
      &:first-child {
        padding-top: ${theme.spacing(3)}px;
      }
    }
  `
)

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      text-align: left;
    }
  `
)

const ScorePaper = styled(Paper)(
  ({ theme }) => css`
    z-index: 1;
    width: fit-content;
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down('sm')} {
      width: 48%;
    }
  `
)

const DrillIconScoreCard: React.FC<Props> = ({ item }) => (
  <ScorePaper elevation={0}>
    <Container>
      <Item key={item.key}>
        <Typography>{item.title}</Typography>
        <Box display="flex" gridGap={2}>
          <Box style={{ display: 'grid', alignSelf: 'center' }}>
            {item.key === DrillType.CompletionBased && <DrillCompletionIcon />}
            {item.key === DrillType.CoachDefined && <DrillCoachDefinedIcon />}
            {item.key === DrillType.DynamicPerformance && (
              <DrillDynamicPerformanceIcon />
            )}
          </Box>
          <Typography variant="h4" component="p">
            <Box component="span">{item.value || ''}</Box>
          </Typography>
        </Box>
      </Item>
    </Container>
  </ScorePaper>
)

export default React.memo(DrillIconScoreCard)
