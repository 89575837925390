import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import Close from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {
  Dialog,
  Toolbar,
  MainContentBackground,
  XsPaddingZeroContainer,
} from 'src/modules/common'
import { fadeVariants, defaultTransition } from 'src/utils/animations'
import {
  drillDetailsDialogOpenSelector,
  selectedDrillSelector,
  updateDetailsDialogVisibility,
} from 'src/store/drillsSlice'
import DrillSummary from './DrillSummary'
import { breakpoints, Routes } from 'src/utils/constants'
import { useHistory } from 'react-router-dom'
import { Box, Button, Container } from '@material-ui/core'
import { Trans } from 'react-i18next'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { updateDialogVisibility as updateAssignToPlayerDialogVisibility } from 'src/store/scheduleSlice'

const I18N_KEY = 'Drills'

const HeaderContainer = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2, 2, 4, 2)};
    }
  `
)

const Footer = styled.footer(
  ({ theme }) =>
    css`
      top: auto;
      bottom: 0;
      width: 100%;
      position: fixed;
      background-color: #fff;
      padding: ${theme.spacing(2, 0)};
      box-shadow: 0px 0px 15px rgba(43, 42, 98, 0.1);

      & > div {
        display: flex;
      }

      ${theme.breakpoints.down(breakpoints.mobile)} {
        left: 0;
        right: 0;
      }
    `
)

const StyledContainer = styled(Container)(
  () => css`
    display: flex;
    justify-content: space-between;
  `
)

const CoachDrillDetailsDialog: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const { t } = useTranslation()
  // const { format } = useContext(TranslationContext)!
  const dialogOpen = useSelector(drillDetailsDialogOpenSelector)
  // const isLoading = useSelector(selectedRoundLoadingSelector)

  const drillData = useSelector(selectedDrillSelector)

  const closeDialog = () => {
    dispatch(updateDetailsDialogVisibility({ isOpen: false }))
    history.push(Routes.CoachPracticeDrills)
  }

  const handleEditDrill = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    alert('Edit Drill not yet implemented')
  }

  const handleAssignDrill = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatch(updateAssignToPlayerDialogVisibility({ isOpen: true }))
  }

  return (
    <Dialog open={dialogOpen}>
      {/* {isLoading ? (
        <GenericLoader
          text={t(`${I18N_KEY}.loadingText`, 'Getting round data')}
        />
      ) : ( */}
      <>
        <AppBar elevation={0} color="default" position="relative">
          <XsPaddingZeroContainer>
            <Toolbar>
              <IconButton data-cy="dialog-close" onClick={closeDialog}>
                <Close />
              </IconButton>
            </Toolbar>
            <HeaderContainer>
              <Typography variant="h1">{drillData?.activityName}</Typography>
            </HeaderContainer>
          </XsPaddingZeroContainer>
        </AppBar>
        <MainContentBackground>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              exit="exit"
              initial="enter"
              animate="visible"
              variants={fadeVariants}
              transition={defaultTransition}
              key={drillData?.uuid}
            >
              {drillData ? <DrillSummary drill={drillData} /> : null}
            </motion.div>
          </AnimatePresence>
        </MainContentBackground>
        <Footer>
          <StyledContainer>
            <Button onClick={closeDialog}>
              <Typography color="error">
                <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
              </Typography>
            </Button>

            <Box style={{ display: 'flex', gap: '1rem' }}>
              <Button
                onClick={handleEditDrill}
                variant="outlined"
                color="primary"
              >
                <Trans i18nKey={`${I18N_KEY}.EditDrillButtonLabel`}>
                  Edit Drill
                </Trans>
              </Button>
              <LoaderButton
                loading={false}
                disabled={false}
                onClick={handleAssignDrill}
                variant="contained"
                color="primary"
              >
                <Trans i18nKey={`${I18N_KEY}.assignDrill`}>Assign Drill</Trans>
              </LoaderButton>
            </Box>
          </StyledContainer>
        </Footer>
      </>
      {/* )} */}
    </Dialog>
  )
}

export default CoachDrillDetailsDialog
