import React, { useState, MouseEvent, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'

import { Box, TablePagination } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'

import DrillRow from './CoachDrillRow'
import ActionConfirmDialog from 'src/modules/activity/DeleteConfirmDialog'
import {
  TableContainer,
  TableCell,
  PaginationContainer,
} from 'src/components/dataDisplay/tableStyles'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'
import SearchTextField from 'src/components/inputs/SearchTextField'
import usePracticeFilters from 'src/hooks/usePracticeFilters'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { PAGINATION_LIMIT, Routes } from 'src/utils/constants'

import { coachSelector } from 'src/store/coachSlice'
import {
  deleteCoachDrill,
  drillsSelector,
  duplicateCoachDrill,
  getCoachDrills,
  totalDrillsSelector,
  updateSelectedDrillByUuid,
} from 'src/store/drillsSlice'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { updateDialogVisibility as updateAssignToPlayerDialogVisibility } from 'src/store/scheduleSlice'

const I18N_KEY = 'CoachSessionsTable'

const OverviewContainer = styled(Box)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 0)};
  `
)

const CoachSessionsTable: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [deleteUuid, setDeleteUuid] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const drillColumns = [
    t(`${I18N_KEY}.name`, 'Drill Name'),
    t(`${I18N_KEY}.author`, 'Author'),
    t(`${I18N_KEY}.category`, 'Category'),
    t(`${I18N_KEY}.subcategory`, 'Sub-Category'),
    t(`${I18N_KEY}.drillType`, 'Drill Type'),
  ]

  const drills = useSelector(drillsSelector)
  const totalDrills = useSelector(totalDrillsSelector)

  const coach = useSelector(coachSelector)

  const [searchInput, setSearchInput] = useState('')
  const handleChangeSearchInput = (value: string = '') => {
    if (value !== searchInput) {
      // dispatch(requestInProgress())
      setSearchInput(value)
    }
  }
  const onChangeSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeSearchInput(e.target.value)
  }
  const { handleSearchTermChange } = usePracticeFilters()
  useDebounce(
    () => {
      handleSearchTermChange(searchInput)
      // First page should be 1
      setPage(1)
    },
    500,
    [searchInput, dispatch]
  )

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getCoachDrills(newPage * PAGINATION_LIMIT))
    setPage(newPage)
  }

  const handleDrillClick = (event: MouseEvent<HTMLTableRowElement>) => {
    const selectedDrillUuid = event.currentTarget.id
    dispatch(updateSelectedDrillByUuid({ selectedDrillUuid }))
    history.push(
      Routes.CoachPracticeDrillDetail.replace(':drillUuid', selectedDrillUuid)
    )
  }

  const handleEditClick =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      alert(`Edit Drill not yet implemented`)
    }

  const generateHandleDuplicate =
    (uuid: string) => async (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()

      try {
        await dispatch(duplicateCoachDrill(uuid))
      } catch {
        dispatch(
          openToast(
            getErrorToast(
              t(`${I18N_KEY}.duplicateToast`, 'Could not duplicate drill')
            )
          )
        )
      }
    }

  const generateDeleteClick =
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      setDeleteUuid(uuid)
    }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await dispatch(deleteCoachDrill(deleteUuid))
      setIsDeleting(false)
    } catch {
      dispatch(
        openToast(
          getErrorToast(t(`${I18N_KEY}.deleteToast`, 'Could not delete drill'))
        )
      )
    }
    setDeleteUuid('')
  }

  const handleAssignToPlayerClick =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      dispatch(updateSelectedDrillByUuid({ selectedDrillUuid: uuid }))
      dispatch(updateAssignToPlayerDialogVisibility({ isOpen: true }))
    }

  const handleAddToSessionClick =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      alert(`Add To Session not yet implemented`)
    }

  const closeDialog = () => {
    setDeleteUuid('')
  }

  return (
    <OverviewContainer>
      <SearchTextField
        placeholder={t(`${I18N_KEY}.searchDrills`, 'Search drills')}
        cleanAriaLabel={t(`${I18N_KEY}.clearSearch`, 'Clear search')}
        onCleanSearch={() => handleChangeSearchInput()}
        onChange={onChangeSearch}
        value={searchInput}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {drillColumns.map(column => (
                <TableCell key={column} align="left">
                  <Typography variant="caption" color="textSecondary">
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {drills.map((drill, index) => (
              <DrillRow
                drill={drill}
                key={index}
                coach={coach}
                handleDrillClick={handleDrillClick}
                handleEditClick={handleEditClick(drill.uuid)}
                handleDuplicateClick={generateHandleDuplicate(drill.uuid)}
                handleDeleteClick={generateDeleteClick(drill.uuid)}
                handleAssignToPlayerClick={handleAssignToPlayerClick(
                  drill.uuid
                )}
                handleAddToSessionClick={handleAddToSessionClick(drill.uuid)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        {/* {noRoundsEntered ? (
        <EmptyRounds />
      ) : ( */}
        <TablePagination
          page={page}
          component="div"
          count={totalDrills}
          rowsPerPageOptions={[]}
          rowsPerPage={PAGINATION_LIMIT}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
        {/* )} */}
      </PaginationContainer>
      <ActionConfirmDialog
        open={!!deleteUuid}
        inProgress={isDeleting}
        handleClose={closeDialog}
        handleAction={handleDelete}
        title={t(
          `${I18N_KEY}.dialogTitle`,
          'Are you sure you want to delete this drill?'
        )}
        deleteText={t(`${I18N_KEY}.dialogDeleteText`, 'Delete Drill')}
        description={t(
          `${I18N_KEY}.dialogDescription`,
          'Are you sure you want to remove this Drill. This action can’t be undone.'
        )}
      />
    </OverviewContainer>
  )
}

export default CoachSessionsTable
