import React, { useState, MouseEvent, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'

import { Box, TablePagination } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'

import {
  TableContainer,
  TableCell,
  PaginationContainer,
} from 'src/components/dataDisplay/tableStyles'
import SearchTextField from 'src/components/inputs/SearchTextField'

import styled, { css } from 'styled-components'
import {
  getCoachSessions,
  sessionsSelector,
  totalSessionsSelector,
  updateSelectedSessionByUuid,
} from 'src/store/sessionSlice'
import { coachSelector } from 'src/store/coachSlice'
import SessionRow from './CoachSessionRow'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'
import { PAGINATION_LIMIT } from 'src/utils/constants'
import usePracticeFilters from 'src/hooks/usePracticeFilters'
import { updateDialogVisibility as updateAssignToPlayerDialogVisibility } from 'src/store/scheduleSlice'

const I18N_KEY = 'CoachSessionsTable'

const OverviewContainer = styled(Box)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 0)};
  `
)

const CoachSessionsTable: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  // const [deleteUuid, setDeleteUuid] = useState('')
  // const [isDeleting, setIsDeleting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const drillColumns = [
    t(`${I18N_KEY}.name`, 'Session Name'),
    t(`${I18N_KEY}.author`, 'Author'),
    t(`${I18N_KEY}.drills`, 'Drills'),
    t(`${I18N_KEY}.duration`, 'Est. Duration'),
  ]

  const sessions = useSelector(sessionsSelector)
  const totalSessions = useSelector(totalSessionsSelector)

  const coach = useSelector(coachSelector)

  const [searchInput, setSearchInput] = useState('')
  const handleChangeSearchInput = (value: string = '') => {
    if (value !== searchInput) {
      setSearchInput(value)
    }
  }
  const onChangeSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeSearchInput(e.target.value)
  }
  const { handleSearchTermChange } = usePracticeFilters()
  useDebounce(
    () => {
      handleSearchTermChange(searchInput)
      // First page should be 1
      setPage(1)
    },
    500,
    [searchInput, dispatch]
  )

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getCoachSessions(newPage * PAGINATION_LIMIT))
    setPage(newPage)
  }

  // const handleSessionClick = (event: MouseEvent<HTMLTableRowElement>) => {
  //   const selectedSessionUuid = event.currentTarget.id
  //   // dispatch(updateSelectedSessionByUuid({ selectedSessionUuid }))
  //   alert('Not yet implemented')
  //   // TODO
  //   // const route =
  //   // history.push(
  //   //   route
  //   //     .replace(':drillUuid', selectedDrillUuid)
  //   // )
  // }

  // const handleEditClick =
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
  //     event.stopPropagation()
  //     alert(`Edit Session not yet implemented`)
  //   }

  const handleAssignToPlayerClick =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (uuid: string) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      dispatch(updateSelectedSessionByUuid({ selectedSessionUuid: uuid }))
      dispatch(updateAssignToPlayerDialogVisibility({ isOpen: true }))
    }

  return (
    <OverviewContainer>
      <SearchTextField
        placeholder={t(`${I18N_KEY}.searchSessions`, 'Search sessions')}
        cleanAriaLabel={t(`${I18N_KEY}.clearSearch`, 'Clear search')}
        onCleanSearch={() => handleChangeSearchInput()}
        onChange={onChangeSearch}
        value={searchInput}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {drillColumns.map((column, index) =>
                index === 0 ? (
                  <TableCell key={column} align="left">
                    <Box ml={8}>
                      <Typography variant="caption" color="textSecondary">
                        {column}
                      </Typography>
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell key={column} align="center">
                    <Typography variant="caption" color="textSecondary">
                      {column}
                    </Typography>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session, index) => (
              <SessionRow
                session={session}
                key={index}
                coach={coach}
                handleAssignToPlayerClick={handleAssignToPlayerClick(
                  session.uuid
                )}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        {/* {noRoundsEntered ? (
        <EmptyRounds />
      ) : ( */}
        <TablePagination
          page={page}
          component="div"
          count={totalSessions}
          rowsPerPageOptions={[]}
          rowsPerPage={PAGINATION_LIMIT}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
        {/* )} */}
      </PaginationContainer>
    </OverviewContainer>
  )
}

export default CoachSessionsTable
