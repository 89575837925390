import { DrillGoalType, DrillMeasurement } from 'src/utils/golfConstants'

export type Metric = {
  metricId: string
  activityId: string | null
  unit: string | null
  goalType: string | null
}

type Subcategory = {
  metrics?: Metric[]
} & {
  [key: string]: Subcategory | undefined
}

export const metricMapping: any = {
  drives: {
    M03: {
      metrics: [
        {
          metricId: 'M20',
          activityId: 'A01',
          unit: DrillMeasurement.Yards,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
  },
  approach: {
    M09: {
      metrics: [
        {
          metricId: 'M66',
          activityId: 'A06',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M67',
          activityId: 'A07',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M98',
          activityId: 'A16',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M99',
          activityId: 'A17',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M106',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M107',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M10: {
      metrics: [
        {
          metricId: 'M68',
          activityId: 'A08',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M69',
          activityId: 'A09',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M101',
          activityId: 'A19',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M102',
          activityId: 'A20',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M108',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M109',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M11: {
      metrics: [
        {
          metricId: 'M70',
          activityId: 'A10',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M71',
          activityId: 'A11',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M102',
          activityId: 'A20',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M103',
          activityId: 'A21',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M255',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M256',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M12: {
      metrics: [
        {
          metricId: 'M257',
          activityId: 'A12',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M258',
          activityId: 'A13',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M259',
          activityId: 'A22',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M260',
          activityId: 'A23',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    metrics: [],
  },
  short: {
    M13: {
      metrics: [
        {
          metricId: 'M110',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M111',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M114',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M115',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M227',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M228',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M261',
          activityId: 'A24',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M262',
          activityId: 'A25',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M263',
          activityId: 'A26',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M264',
          activityId: 'A27',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M14: {
      metrics: [
        {
          metricId: 'M112',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M113',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M116',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M117',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M229',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M230',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M265',
          activityId: 'A28',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M15: {
      metrics: [
        {
          metricId: 'M64',
          activityId: 'A04',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M65',
          activityId: 'A05',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M96',
          activityId: 'A14',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M97',
          activityId: 'A15',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M104',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M105',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M16: {
      metrics: [
        {
          metricId: 'M72',
          activityId: 'A29',
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M202',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M203',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    metrics: [],
  },
  putt: {
    M17: {
      metrics: [
        {
          metricId: 'M79',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M122',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M123',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M124',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M125',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },

        {
          metricId: 'M267',
          activityId: 'A33',
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
      ],
    },
    M18: {
      metrics: [
        {
          metricId: 'M80',
          activityId: 'A39',
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M81',
          activityId: 'A40',
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M82',
          activityId: 'A41',
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M126',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M127',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M128',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M129',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M190',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M191',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M193',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M194',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    M19: {
      metrics: [
        {
          metricId: 'M83',
          activityId: null,
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },
        {
          metricId: 'M84',
          activityId: 'A42',
          unit: DrillMeasurement.PuttsMade,
          goalType: [DrillGoalType.PuttsMade],
        },

        {
          metricId: 'M192',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M195',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M196',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M197',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M198',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M199',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M200',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
        {
          metricId: 'M201',
          activityId: null,
          unit: DrillMeasurement.Feet,
          goalType: [
            DrillGoalType.Median,
            DrillGoalType.Best,
            DrillGoalType.Average,
            DrillGoalType.Worst,
          ],
        },
      ],
    },
    metrics: [
      {
        metricId: 'M78',
        activityId: null,
        unit: DrillMeasurement.PuttsMade,
        goalType: [DrillGoalType.PuttsMade],
      },
      {
        metricId: 'M131',
        activityId: null,
        unit: DrillMeasurement.PuttsMade,
        goalType: [DrillGoalType.PuttsMade],
      },
    ],
  },
}

export const findMetricById = (metricId: string): Metric | null => {
  if (!metricId) {
    console.error('findMetricById: Provided metricId is invalid or empty.')
    return null
  }

  if (!metricMapping || typeof metricMapping !== 'object') {
    console.error('findMetricById: metricMapping is not properly initialized.')
    return null
  }

  const searchMetrics = (
    subcategory: Subcategory | undefined
  ): Metric | null => {
    if (!subcategory) return null

    try {
      if (subcategory.metrics) {
        const foundMetric = subcategory.metrics.find(
          m => m.metricId === metricId
        )
        if (foundMetric) return foundMetric
      }

      // Recursively search subcategories
      for (const key in subcategory) {
        if (key !== 'metrics') {
          const found = searchMetrics(subcategory[key] as Subcategory)
          if (found) return found
        }
      }
    } catch (error) {
      console.error(`Find Metric: Error while searching metrics - ${error}`)
    }

    return null
  }

  try {
    for (const categoryKey in metricMapping) {
      const foundMetric = searchMetrics(metricMapping[categoryKey])
      if (foundMetric) return foundMetric
    }
  } catch (error) {
    console.error(`Find Metric: Error while iterating metricMapping - ${error}`)
  }

  console.warn(`Find Metric: Metric with ID "${metricId}" not found.`)
  return null
}
