import React from 'react'

interface Props {
  width?: number
  height?: number
}

const DrillCoachDefinedIcon: React.FC<Props> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1622 0.600586V12.7087C21.1622 16.9868 16.3378 19.9736 12.0811 23.0006C7.82432 19.9736 3 16.9868 3 12.7087V0.600586H21.1622Z"
        fill="#2549BF"
        fillOpacity="0.87"
      />
      <path
        d="M17.7571 4.00586V11.9825C17.7571 14.8008 14.7419 16.7685 12.0814 18.7626C9.42096 16.7685 6.40576 14.8008 6.40576 11.9825V4.00586H17.7571Z"
        fill="#2549BF"
        fillOpacity="0.87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4599 2.30371V11.983C19.4599 14.0502 18.3426 15.6566 17.1249 16.8822C16.1243 17.8894 14.867 18.819 13.7505 19.6446C13.5279 19.8091 13.311 19.9695 13.1027 20.1256L12.0815 20.8911L11.0603 20.1256C10.852 19.9696 10.6351 19.8091 10.4125 19.6446C9.29599 18.819 8.03872 17.8894 7.03807 16.8822C5.82039 15.6566 4.70312 14.0502 4.70312 11.983V2.30371H19.4599ZM17.7572 4.00641H6.40583V11.983C6.40583 14.5597 8.92613 16.4253 11.3915 18.2502C11.6227 18.4214 11.8534 18.5922 12.0815 18.7632C12.3096 18.5922 12.5403 18.4214 12.7716 18.2502C15.2369 16.4253 17.7572 14.5597 17.7572 11.983V4.00641Z"
        fill="white"
      />
      <mask
        id="mask0_40000077_20166"
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="12"
        height="12"
      >
        <rect x="6" y="4" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_40000077_20166)">
        <path
          d="M10.5002 12L10.0877 13.6375C10.0627 13.7458 10.0086 13.8333 9.92522 13.9C9.84189 13.9667 9.74188 14 9.62522 14C9.46688 14 9.33772 13.9375 9.23772 13.8125C9.13772 13.6875 9.10855 13.55 9.15022 13.4L9.50022 12H8.13772C7.97105 12 7.83772 11.9354 7.73772 11.8063C7.63772 11.6771 7.60855 11.5333 7.65022 11.375C7.67522 11.2583 7.73355 11.1667 7.82522 11.1C7.91688 11.0333 8.02105 11 8.13772 11H9.75022L10.2502 9H8.88772C8.72105 9 8.58772 8.93542 8.48772 8.80625C8.38772 8.67708 8.35855 8.53333 8.40022 8.375C8.42522 8.25833 8.48355 8.16667 8.57522 8.1C8.66689 8.03333 8.77105 8 8.88772 8H10.5002L10.9127 6.3625C10.9377 6.25417 10.9919 6.16667 11.0752 6.1C11.1586 6.03333 11.2586 6 11.3752 6C11.5336 6 11.6627 6.0625 11.7627 6.1875C11.8627 6.3125 11.8919 6.45 11.8502 6.6L11.5002 8H13.5002L13.9127 6.3625C13.9377 6.25417 13.9919 6.16667 14.0752 6.1C14.1586 6.03333 14.2586 6 14.3752 6C14.5336 6 14.6627 6.0625 14.7627 6.1875C14.8627 6.3125 14.8919 6.45 14.8502 6.6L14.5002 8H15.8627C16.0294 8 16.1627 8.06458 16.2627 8.19375C16.3627 8.32292 16.3919 8.46667 16.3502 8.625C16.3252 8.74167 16.2669 8.83333 16.1752 8.9C16.0836 8.96667 15.9794 9 15.8627 9H14.2502L13.7502 11H15.1127C15.2794 11 15.4127 11.0646 15.5127 11.1937C15.6127 11.3229 15.6419 11.4667 15.6002 11.625C15.5752 11.7417 15.5169 11.8333 15.4252 11.9C15.3336 11.9667 15.2294 12 15.1127 12H13.5002L13.0877 13.6375C13.0627 13.7458 13.0086 13.8333 12.9252 13.9C12.8419 13.9667 12.7419 14 12.6252 14C12.4669 14 12.3377 13.9375 12.2377 13.8125C12.1377 13.6875 12.1086 13.55 12.1502 13.4L12.5002 12H10.5002ZM10.7502 11H12.7502L13.2502 9H11.2502L10.7502 11Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default React.memo(DrillCoachDefinedIcon)
