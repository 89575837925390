import React from 'react'
import { SummaryContainer, FadingDotIllustration } from 'src/modules/common'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { CoachDrill, DrillType } from 'src/utils/golfConstants'
import styled, { css } from 'styled-components'
import ScoreCard from 'src/components/dataDisplay/ScoreCard'
import { Box } from '@material-ui/core'
import DrillIconScoreCard from 'src/components/dataDisplay/DrillIconScoreCard'
import SummaryTextDisplay from '../createDrillDialog/SummaryTextDisplay'
import { formatTime } from '../../util'

const I18N_KEY = 'DrillSummaryDetails'

interface Props {
  drill: CoachDrill
}

const CardsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    margin: ${theme.spacing(2, 0, 0)};

    & > div:first-child {
      margin-right: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('sm')} {
      & > div:first-child {
        margin: ${theme.spacing(0, 2, 0, 0)};
      }
    }
  `
)

const DrillDetails: React.FC<Props> = ({ drill }) => {
  const { t } = useTranslation()

  const missingValue = t(`${I18N_KEY}.n/a`)

  const subcategory = drill?.subcategories?.[0]
  const subcategoryText =
    subcategory !== undefined
      ? t(`Enums:MetricLabels.${subcategory as string}`)
      : missingValue
  const categories = [
    {
      title: t(`${I18N_KEY}.category`, 'Category'),
      value: drill?.category
        ? t(`Enums:CategoryType.${drill.category}`)
        : missingValue,
    },
    {
      title: t(`${I18N_KEY}.subcategory`, 'Sub-Category'),
      value: subcategoryText,
    },
  ]

  const metric = [
    {
      title: t(`${I18N_KEY}.metric`, 'Metric'),
      value: drill?.metricId
        ? t(`Enums:MetricLabels.${drill.metricId}`)
        : missingValue,
    },
  ]

  const goalType = [
    {
      title: t(`${I18N_KEY}.goalType`, 'Goal Type'),
      value: drill?.goalType
        ? t(`Enums:DrillGoalType.${drill.goalType}`)
        : missingValue,
    },
  ]

  const goalSuccess = [
    {
      title: t(`${I18N_KEY}.goalSuccess`, 'Goal Success'),
      value: drill?.go ? t(`Enums:ValueGo.${drill.go}`) : missingValue,
    },
  ]

  const goal = [
    {
      title: t(`${I18N_KEY}.goal`, 'Goal'),
      value: drill?.goal ? drill.goal : missingValue,
    },
  ]

  const attempts = [
    {
      title: t(`${I18N_KEY}.attempts`, 'Attempts'),
      value: drill?.attempts ? drill.attempts : missingValue,
    },
  ]

  const unit = [
    {
      title: t(`${I18N_KEY}.unit`, 'Unit'),
      value: drill?.unit
        ? t(`Enums:DrillMeasurement.${drill.unit}`, {
            defaultValue: drill.unit,
          })
        : missingValue,
    },
  ]

  const difficulty = [
    {
      title: t(`${I18N_KEY}.difficulty`, 'Difficulty'),
      value: drill?.difficulty ? `${drill.difficulty} % harder` : missingValue,
    },
  ]

  return (
    <SummaryContainer>
      <FadingDotIllustration />
      <Container>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.drillOverview`}>Drill Overview</Trans>
        </Typography>
        <CardsContainer>
          <ScoreCard items={categories} />
          {drill.estimatedTime ? (
            <ScoreCard
              items={[
                {
                  title: t(`${I18N_KEY}.estimatedTime`, 'Estimated Time'),
                  value: formatTime(
                    drill.estimatedTime,
                    t(`${I18N_KEY}.hr`, { defaultValue: 'hr' }),
                    t(`${I18N_KEY}.min`, { defaultValue: 'min' })
                  ),
                },
              ]}
            />
          ) : null}
        </CardsContainer>
        <Box mt={4}>
          <Typography variant="h3">
            <Trans i18nKey={`${I18N_KEY}.drillSummary`}>Drill Summary</Trans>
          </Typography>
          <CardsContainer>
            {drill.drillType ? (
              <DrillIconScoreCard
                item={{
                  title: t(`${I18N_KEY}.drillType`, 'Drill Type'),
                  value: t(`CoachDrillRow.${drill.drillType}`),
                  key: drill.drillType,
                }}
              />
            ) : null}
            {drill.metricId && drill.metricId !== 'n/a' ? (
              <ScoreCard items={metric} />
            ) : null}
          </CardsContainer>
          {drill.drillType !== DrillType.CompletionBased && (
            <Box mt={4}>
              <CardsContainer>
                {drill.goalType ? <ScoreCard items={goalType} /> : null}
                {drill.go ? <ScoreCard items={goalSuccess} /> : null}
                {drill.goal ? <ScoreCard items={goal} /> : null}
                {drill.unit ? <ScoreCard items={unit} /> : null}
                {drill.attempts ? <ScoreCard items={attempts} /> : null}
                {drill.difficulty &&
                drill.drillType === DrillType.DynamicPerformance ? (
                  <ScoreCard items={difficulty} />
                ) : null}
              </CardsContainer>
            </Box>
          )}
        </Box>
        <Box>
          {drill.description ? (
            <Box mt={4}>
              <SummaryTextDisplay
                label={t(`${I18N_KEY}.description`, 'Description')}
                value={drill.description}
                fullWidth
              />
            </Box>
          ) : null}
          {drill.coachNotes ? (
            <Box mt={4}>
              <SummaryTextDisplay
                label={t(`${I18N_KEY}.coachNotes`, 'Coach Notes')}
                value={drill.coachNotes}
                fullWidth
              />
            </Box>
          ) : null}
        </Box>
      </Container>
    </SummaryContainer>
  )
}

export default React.memo(DrillDetails)
