import React from 'react'
import { Box, Typography } from '@material-ui/core'

// import { FieldErrors } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'
import { Trans, useTranslation } from 'react-i18next'
import { Label } from 'src/components/inputs/FormTextField'

import { useSelector } from 'react-redux'
import { coachSelector } from 'src/store/coachSlice'
import SummaryTextDisplay from './SummaryTextDisplay'
import Divider from '@material-ui/core/Divider'
import { DrillType } from 'src/utils/golfConstants'

const I18N_KEY = 'CreateDrillDialog'

interface Props {
  control: Control
  // isDirty: boolean
  // isValid: boolean
  // isSubmitting: boolean
  // errors: FieldErrors
}

const DrillSummary: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation()
  const coach = useSelector(coachSelector)
  const coachFullName = coach.firstName + ' ' + coach.lastName

  const drillType = control.getValues('drillType')
  const drillTypeText = t(`${I18N_KEY}.${drillType}`, 'Drill Type')
  const customOrMetric = control.getValues('customOrMetric')

  const goalType = control.getValues('goalType')
  const goalTypeText = t(`Enums:DrillGoalType.${goalType}`)

  const subcategory = control.getValues('subcategory')
  const subcategoryText =
    subcategory === 'n/a'
      ? t(`${I18N_KEY}.n/a`)
      : t(`Enums:MetricLabels.${subcategory}`)

  const metric = control.getValues('metric')
  const metricText = t(`Enums:MetricLabels.${metric}`)

  const difficulty = control.getValues('difficulty')
  const difficultyText = `${difficulty} % ${t(`${I18N_KEY}.harder`, 'harder')}`

  const unit = control.getValues('unit')
  const unitText = t(`Enums:DrillMeasurement.${unit}`, {
    defaultValue: unit,
  })

  return (
    <>
      <Box mt={4} mb={5} display={'flex'}>
        <SummaryTextDisplay
          label={t(`${I18N_KEY}.drillTitle`, 'Drill Title')}
          value={control.getValues('drillTitle')}
        />
        <Box width={'50%'}>
          <Label variant="caption" color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.drillAuthor`}>Drill Author</Trans>
          </Label>
          <Typography>
            {coachFullName} <span style={{ color: '#bcbcbc' }}>(You)</span>
          </Typography>
        </Box>
      </Box>
      <Box mt={4} mb={5} display={'flex'}>
        <SummaryTextDisplay
          label={t(`${I18N_KEY}.drillType`, 'Drill Type')}
          value={drillTypeText}
          formKey={drillType}
        />
        <SummaryTextDisplay
          label={t(`${I18N_KEY}.category`, 'Category')}
          value={
            control.getValues('category') === 'n/a'
              ? t(`${I18N_KEY}.n/a`)
              : t(`Enums:CategoryType.${control.getValues('category')}`)
          }
        />
      </Box>
      <Box mt={4} mb={5} display={'flex'}>
        <SummaryTextDisplay
          label={t(`${I18N_KEY}.subcategory`, 'Sub-Category')}
          value={subcategoryText}
        />
        {metric && (
          <SummaryTextDisplay
            label={t(`${I18N_KEY}.metric`, 'Metric')}
            value={metricText}
          />
        )}
      </Box>

      {drillType === DrillType.CompletionBased &&
        control.getValues('description').length > 0 && (
          <>
            <Divider />
            <Box mt={4} mb={5} display={'flex'}>
              <SummaryTextDisplay
                label={t(`${I18N_KEY}.description`, 'Description')}
                value={control.getValues('description')}
              />
            </Box>
          </>
        )}

      {drillType === DrillType.CoachDefined && (
        <>
          {customOrMetric === 'custom' && (
            <>
              <Divider />
              {control.getValues('description')?.length > 0 && (
                <Box mt={4} mb={5} display="flex">
                  <SummaryTextDisplay
                    label={t(
                      `${I18N_KEY}.goalDescription`,
                      'Custom Goal Description'
                    )}
                    value={control.getValues('description')}
                  />
                </Box>
              )}
              <Box mt={4} mb={5} display="flex">
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.go`, 'Goal Success')}
                  value={t(`Enums:ValueGo.${control.getValues('go')}`)}
                />
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.goal`, 'Goal')}
                  value={control.getValues('goal')}
                />
              </Box>
              <Box mt={4} mb={5} display="flex">
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.attempts`, 'Attempts')}
                  value={control.getValues('attempts')}
                />
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.unit`, 'Unit')}
                  value={unitText}
                />
              </Box>
            </>
          )}

          {customOrMetric === 'metric' && (
            <>
              <Divider />
              <Box mt={4} mb={5} display="flex">
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.goalType`, 'Goal Type')}
                  value={goalTypeText}
                />
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.goal`, 'Goal')}
                  value={control.getValues('goal')}
                />
              </Box>
              <Box mt={4} mb={5} display="flex">
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.attempts`, 'Attempts')}
                  value={control.getValues('attempts')}
                />
                <SummaryTextDisplay
                  label={t(`${I18N_KEY}.unit`, 'Unit')}
                  value={unitText}
                />
              </Box>
            </>
          )}
        </>
      )}

      {drillType === DrillType.DynamicPerformance &&
        control.getValues('coachNotes').length > 0 && (
          <>
            <Divider />
            <Box mt={4} mb={5} display={'flex'}>
              <SummaryTextDisplay
                label={t(`${I18N_KEY}.attempts`, 'Attempts')}
                value={control.getValues('attempts')}
              />
              <SummaryTextDisplay
                label={t(`${I18N_KEY}.difficulty`, 'Difficulty')}
                value={difficultyText}
              />
            </Box>
            <Box mt={4} mb={5} display={'flex'}>
              <SummaryTextDisplay
                label={t(`${I18N_KEY}.coachNotes`, 'Coach Notes')}
                value={control.getValues('coachNotes')}
              />
            </Box>
          </>
        )}
    </>
  )
}

export default DrillSummary
