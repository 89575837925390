import React, { useMemo } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Select from 'src/components/inputs/Select'
import { findMetricById, Metric, metricMapping } from './data/MetricMapping'
import { Options } from 'src/components/inputs/FormSelect'
import { Box, FormControl } from '@material-ui/core'
import { Label } from 'src/components/Label'
import HelpTooltip from 'src/components/dataDisplay/HelpTooltip'

const I18N_KEY = 'CreateDrillDialog'

interface Props {
  control: Control
  errors: FieldErrors
  category?: string
  subcategory?: string
}

interface ErrorProps {
  $active: boolean
}

const StyledSelect = styled(Select)`
  margin-top: 6px;
`

const StyledContainer = styled(FormControl)`
  position: relative;
`

const ErrorMessage = styled.span<ErrorProps>(
  ({ theme, $active }) => css`
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${$active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${$active ? -24 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const MetricSelection: React.FC<Props> = ({
  control,
  errors,
  category,
  subcategory,
}) => {
  const fieldError = errors && errors?.metric
  const hasError = !!fieldError

  const { t } = useTranslation()

  const getMetricOptions = (
    category?: string,
    subcategory?: string
  ): Options => {
    if (!category || !subcategory) {
      return []
    }

    const categoryData = metricMapping[category]
    if (!categoryData) return []

    let metrics: Metric[] = []

    if (subcategory === 'metrics') {
      metrics = categoryData.metrics || []
    } else {
      metrics = categoryData[subcategory]?.metrics || []
    }

    const metricOptions = metrics.map(({ metricId }) => ({
      label: t(`Enums:MetricLabels.${metricId}`),
      value: metricId,
    }))

    metricOptions.push()

    return metricOptions
  }

  const metricOptions = useMemo(
    () => getMetricOptions(category, subcategory),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, subcategory]
  )

  return (
    <>
      <Box display="flex">
        <Label $hasError={hasError} htmlFor="category">
          <Trans i18nKey={`${I18N_KEY}.metric`}>Metric</Trans>
        </Label>
        <HelpTooltip
          translationKey={`${I18N_KEY}`}
          translationValue="metricTooltip"
          title="Determines the metric that will be used to calculate the goal"
        />
      </Box>
      <Controller
        name="metric"
        control={control}
        defaultValue=""
        render={({ onChange, ...renderProps }) => (
          <StyledContainer>
            <StyledSelect
              disabled={!subcategory || subcategory === 'n/a'}
              options={metricOptions}
              variant="outlined"
              placeholder="Select a Metric"
              onChange={e => {
                const selectedMetric: string = e.target.value as string
                const metricMapping = findMetricById(selectedMetric)
                if (metricMapping?.goalType) {
                  control.setValue('goalType', metricMapping?.goalType[0])
                  control.setValue('unit', metricMapping?.unit)
                }
                onChange(e)
              }}
              {...renderProps}
              fullWidth
            />
            <ErrorMessage $active={hasError}>
              {fieldError?.message}
            </ErrorMessage>
          </StyledContainer>
        )}
      />
    </>
  )
}

export default MetricSelection
