import { useTranslation } from 'react-i18next'
import { DrillGoalType } from 'src/utils/golfConstants'

const useDrillGoalTypeOptions = () => {
  const { t } = useTranslation()
  const goalTypesOptions = [
    {
      label: t(`Enums:DrillGoalType.${DrillGoalType.Best}`),
      description: t(`DrillGoalType.best_desc`, 'Best'),
      value: DrillGoalType.Best,
    },
    {
      label: t(`Enums:DrillGoalType.${DrillGoalType.Median}`),
      description: t(`DrillGoalType.median_desc`, 'Median'),
      value: DrillGoalType.Median,
    },
    {
      label: t(`Enums:DrillGoalType.${DrillGoalType.Average}`),
      description: t(`DrillGoalType.average_desc`, 'Average'),
      value: DrillGoalType.Average,
    },
    {
      label: t(`Enums:DrillGoalType.${DrillGoalType.Worst}`),
      description: t(`DrillGoalType.worst_desc`, 'Worst'),
      value: DrillGoalType.Worst,
    },
    {
      label: t(`Enums:DrillGoalType.${DrillGoalType.PuttsMade}`),
      description: t(`DrillGoalType.putts_made_desc`, 'Putts Made'),
      value: DrillGoalType.PuttsMade,
    },
  ]

  const puttOnlyGoalOptions = goalTypesOptions.map(option => ({
    ...option,
    disabled: option.value !== DrillGoalType.PuttsMade,
  }))

  const bestToWorstGoalOptions = goalTypesOptions.map(option => ({
    ...option,
    disabled: option.value === DrillGoalType.PuttsMade,
  }))

  return { goalTypesOptions, puttOnlyGoalOptions, bestToWorstGoalOptions }
}

export default useDrillGoalTypeOptions
