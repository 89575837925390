import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'

import { Box, Typography } from '@material-ui/core'

import CategorySelection from './CategorySelection'

import MetricSelection from './MetricSelection'
import { Label } from 'src/components/inputs/FormTextField'
import styled from 'styled-components'
import Select from 'src/components/inputs/Select'
import HookFormTextareaAutosize from 'src/components/inputs/HookFormTextareaAutosize'
import HookNumberChanger from 'src/components/inputs/HookNumberChanger'
import useDrillGoalTypeOptions from 'src/hooks/useDrillGoalTypeOptions'
import { CategoryFilter } from 'src/utils/constants'
import HelpTooltip from 'src/components/dataDisplay/HelpTooltip'

const I18N_KEY = 'DynamicPerformance'

interface Props {
  control: Control
  errors: FieldErrors
  // isDirty: boolean
  // isValid: boolean
  // isSubmitting: boolean
}

const StyledSelect = styled(Select)`
  margin-top: 6px;
`

const DynamicPerformance: React.FC<Props> = ({
  control,
  errors,
  // isDirty,
  // isValid,
}) => {
  const { t } = useTranslation()
  const { puttOnlyGoalOptions, bestToWorstGoalOptions } =
    useDrillGoalTypeOptions()

  const selectedCategory: string = useWatch({ control, name: 'category' }) ?? ''
  const selectedSubcategory: string =
    useWatch({ control, name: 'subcategory' }) ?? ''

  return (
    <>
      <Box mt={4} mb={5} width="100%">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">
            <Trans i18nKey={`${I18N_KEY}.categoryMetricTitle`}>
              Category & Metric
            </Trans>
          </Typography>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="categoryAndMetricTooltip"
            title="Determine the category, sub-category, and metric for the desired drill"
          />
        </Box>
        <Box mt={4} mb={5} width="100%">
          <CategorySelection control={control} errors={errors} />
        </Box>
        <Box mt={4} mb={5} width="100%">
          <MetricSelection
            control={control}
            category={selectedCategory}
            subcategory={selectedSubcategory}
            errors={errors}
          />
        </Box>
      </Box>
      <Box mt={4} mb={5} width="100%">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">
            <Trans i18nKey={`${I18N_KEY}.attemptsDifficultyTitle`}>
              Attempts & Difficulty
            </Trans>
          </Typography>
          <HelpTooltip
            translationKey={`${I18N_KEY}`}
            translationValue="attemptsAndDifficultyTooltip"
            title="Set the desired difficulty for the drill and determine the number of attempts taken by the player"
          />
        </Box>
        <Box mt={2} display="flex" gridGap="1rem">
          <Box width="30rem">
            <Box display="flex">
              <Label
                variant="caption"
                // color={!isValid && isDirty ? 'error' : undefined}
              >
                <Trans i18nKey={`${I18N_KEY}.goalType`}>Goal Type</Trans>
              </Label>
              <HelpTooltip
                translationKey={`${I18N_KEY}`}
                translationValue="goalTypeTooltip"
                title="How should a player record their results, e.g. their best result or the average of their results"
              />
            </Box>
            <Controller
              name="goalType"
              control={control}
              defaultValue=""
              render={({ onChange, ...renderProps }) => (
                <StyledSelect
                  options={
                    selectedCategory === CategoryFilter.Putting
                      ? puttOnlyGoalOptions
                      : bestToWorstGoalOptions
                  }
                  variant="outlined"
                  placeholder="Select your goal type"
                  onChange={onChange}
                  {...renderProps}
                  showDescriptiveText
                  fullWidth
                />
              )}
            />
          </Box>
          <Box width="26rem" display="flex" gridGap="1rem">
            <HookNumberChanger
              control={control}
              name="attempts"
              // errors={errors}
              defaultValue={10}
              label={t(`${I18N_KEY}.attemptsTitle`, 'Attempts')}
              toolTipTranslationKey={`${I18N_KEY}`}
              toolTipTranslationValue="attemptsTooltip"
              toolTipTitle="How many attempts will the player take for the drill"
            />
            <HookNumberChanger
              control={control}
              name="difficulty"
              // errors={errors}
              showPercentage
              label={t(`${I18N_KEY}.difficultyTitle`, 'Difficulty')}
              toolTipTranslationKey={`${I18N_KEY}`}
              toolTipTranslationValue="difficultyTooltip"
              toolTipTitle="How much harder will the drill be vs. on course performance"
            />
          </Box>
        </Box>
        <Box mt={4} mb={5} width="100%">
          <HookFormTextareaAutosize
            name="coachNotes"
            label={t(`${I18N_KEY}.coachNotes`, 'Coach Notes')}
            minRows={8}
            control={control}
            toolTipTranslationKey={`${I18N_KEY}`}
            toolTipTranslationValue="coachNotesTooltip"
            toolTipTitle="Additional notes for the player"
          />
        </Box>
      </Box>
    </>
  )
}

export default DynamicPerformance
