import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'

import { Label } from '../Label'
import { DatePicker, DatePickerProps } from '@material-ui/pickers'

import { DateFormat, Languages } from 'src/utils/constants'
import { FormControl } from '@material-ui/core'

interface HookDatePickerProps {
  name: string
  label?: string
  control: Control
  defaultDate?: Date
  disableFuture?: boolean
  datePickerProps?: Omit<DatePickerProps, 'value' | 'onChange'>
  errors?: FieldErrors
}

const StyledContainer = styled(FormControl)`
  position: relative;
`

const StyledPicker = styled(DatePicker)(
  ({ theme }) => css`
    margin: ${theme.spacing(0.5, 0, 0)};
  `
)

const ErrorMessage = styled.span<{ $active?: boolean }>(
  ({ theme, $active }) => css`
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${$active ? 1 : 0};
    transition: all 0.3s ease-out;
    top: 5rem;
    bottom: ${$active ? -24 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const today = new Date()

const HookDatePicker: React.FC<HookDatePickerProps> = ({
  name,
  label,
  control,
  defaultDate,
  disableFuture = true,
  datePickerProps,
  errors,
}) => {
  const { i18n } = useTranslation()
  const fieldError = errors && errors[name]
  const hasError = !!fieldError

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultDate || today}
      render={props => (
        <StyledContainer>
          {label && (
            <Label $hasError={hasError} htmlFor={name}>
              {label}
            </Label>
          )}
          <StyledPicker
            autoOk
            fullWidth
            helperText=""
            disableFuture={disableFuture}
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{ endAdornment: <CalendarIcon /> }}
            format={DateFormat[i18n.language as Languages].datePicker}
            {...props}
            {...datePickerProps}
            data-cy="date-picker"
          />
          <ErrorMessage $active={hasError}>{fieldError?.message}</ErrorMessage>
        </StyledContainer>
      )}
    />
  )
}

export default HookDatePicker
