// Ie. 2 hr 45 min
export function formatTime(
  minutes: number,
  hourFormat: string,
  minuteFormat: string
): string {
  if (minutes < 0) return 'Invalid time'

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours} ${hourFormat} ${remainingMinutes} ${minuteFormat}`
  } else if (hours > 0) {
    return `${hours} ${hourFormat}`
  } else {
    return `${remainingMinutes} ${minuteFormat}`
  }
}
