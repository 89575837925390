import React from 'react'
import styled, { css } from 'styled-components'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import {
  TextField,
  FormControl,
  TextFieldProps,
  Button,
  InputAdornment,
  Box,
} from '@material-ui/core'
import { Label } from '../Label'
import { Remove, Add } from '@material-ui/icons'
import HelpTooltip from '../dataDisplay/HelpTooltip'

interface ExtraProps {
  name: string
  control: Control
  errors?: FieldErrors
  defaultValue?: number
  showPercentage?: boolean
  toolTipTranslationKey?: string
  toolTipTranslationValue?: string
  toolTipTitle?: string
}

export type HookFormTextFieldProps = TextFieldProps & ExtraProps

interface ErrorProps {
  $active: boolean
}

const StyledContainer = styled(FormControl)`
  position: relative;
`

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`

const ErrorMessage = styled.span<ErrorProps>(
  ({ theme, $active }) => css`
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${$active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${$active ? -24 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const HookNumberChanger: React.FC<HookFormTextFieldProps> = ({
  name,
  label,
  errors,
  control,
  className,
  defaultValue = 0,
  variant = 'outlined',
  showPercentage = false,
  toolTipTranslationKey,
  toolTipTranslationValue,
  toolTipTitle,
  onChange: customOnChange,
  ...props
}) => {
  const fieldError = errors && errors[name]
  const hasError = !!fieldError

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ onChange, value, ...renderProps }) => (
        <StyledContainer className={className}>
          <Box display="flex">
            {label && (
              <Label $hasError={hasError} htmlFor={name}>
                {label}
              </Label>
            )}
            {toolTipTranslationKey &&
              toolTipTranslationValue &&
              toolTipTitle && (
                <HelpTooltip
                  translationKey={toolTipTranslationKey}
                  translationValue={toolTipTranslationValue}
                  title={toolTipTitle}
                />
              )}
          </Box>
          <StyledTextField
            id={name}
            error={hasError}
            type="number"
            variant={variant}
            value={value}
            style={{ marginTop: '0.35rem' }}
            onChange={e => {
              const newValue = Number(e.target.value)
              onChange(newValue)
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ padding: 0 }} position="start">
                  <Button
                    variant="text"
                    color="primary"
                    style={{ minWidth: '32px', padding: '1rem' }}
                    onClick={() => {
                      if (value > 0) {
                        onChange(value - 1)
                      }
                    }}
                    disabled={value <= 0}
                  >
                    <Remove />
                  </Button>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {showPercentage && (
                    <InputAdornment position="start">%</InputAdornment>
                  )}
                  <InputAdornment position="end">
                    <Button
                      variant="text"
                      color="primary"
                      style={{ minWidth: '32px', padding: '1rem' }}
                      onClick={() => onChange(value + 1)}
                      disabled={showPercentage && value >= 100}
                    >
                      <Add />
                    </Button>
                  </InputAdornment>
                </>
              ),
            }}
            inputProps={{
              style: {
                textAlign: 'center',
                paddingRight: '0.25rem',
                minWidth: '1.75rem',
              },
            }}
            {...props}
          />
          <ErrorMessage $active={hasError}>{fieldError?.message}</ErrorMessage>
        </StyledContainer>
      )}
    />
  )
}

export default React.memo(HookNumberChanger)
