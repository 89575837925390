import React, { forwardRef } from 'react'
import {
  Box,
  Radio,
  RadioGroup,
  RadioProps,
  Typography,
} from '@material-ui/core'

import { Controller, FieldErrors } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'
import { Trans, useTranslation } from 'react-i18next'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import styled, { css } from 'styled-components'
import { Label } from 'src/components/inputs/FormTextField'

import DrillCompletionIcon from '../../../../assets/svgComponents/DrillCompletionIcon'
import DrillDynamicPerformanceIcon from 'src/assets/svgComponents/DrillDynamicPerformanceIcon'
import DrillCoachDefinedIcon from 'src/assets/svgComponents/DrillCoachDefinedIcon'
import { DrillType } from 'src/utils/golfConstants'

const I18N_KEY = 'CreateDrillDialog'

interface Props {
  control: Control
  // isDirty: boolean
  // isValid: boolean
  // isSubmitting: boolean
  errors: FieldErrors
}

const DrillTypeContainer = styled.div<{ $isChecked?: boolean }>(
  ({ $isChecked }) => css`
    min-width: 222px;
    min-height: 228px;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid ${$isChecked ? '#154ac6' : '#bcbcbc'};
    border-radius: 0.35rem;
  `
)

const StyleDrillRadio = styled(Radio)(
  () => css`
    color: #bcbcbc;
    &.Mui-checked {
      color: #154ac6;
    }
  `
)

const DrillDetails: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()

  const DrillTypeRadio = forwardRef<
    HTMLButtonElement,
    Pick<RadioProps, 'checked' | 'value' | 'name'> & {
      isDisabled?: boolean
      children?: React.ReactNode
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  >(function DrillTypeRadio(props, ref) {
    const { checked, isDisabled, value, children, ...rest } = props
    const radioId = `drill-type-${value}`

    const handleRadioButtonClick = () => {
      if (isDisabled) return
      const radioElement = document.getElementById(radioId) as HTMLInputElement
      if (radioElement) {
        radioElement.click()
      }
    }

    return (
      <DrillTypeContainer
        $isChecked={checked}
        onClick={handleRadioButtonClick}
        style={{ userSelect: 'none' }}
      >
        {value === DrillType.CompletionBased && (
          <>
            <DrillCompletionIcon width={60} height={60} />
            <Box mt={2}>
              <Typography variant="h5" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.${value}`}>{value}</Trans>
              </Typography>
            </Box>
            <Box padding={2}>
              <Typography
                color="textSecondary"
                style={{ fontSize: 'smaller', textAlign: 'center' }}
              >
                {t(
                  `${I18N_KEY}.completionCardDescription`,
                  'Ex. Make 50 three-foot putts'
                )}
              </Typography>
            </Box>
          </>
        )}
        {value === DrillType.CoachDefined && (
          <>
            <DrillCoachDefinedIcon width={60} height={60} />
            <Box mt={2}>
              <Typography variant="h5" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.${value}`}>{value}</Trans>
              </Typography>
            </Box>
            <Box padding={2}>
              <Typography
                color="textSecondary"
                style={{ fontSize: 'smaller', textAlign: 'center' }}
              >
                {t(
                  `${I18N_KEY}.coachDefinedCardDescription`,
                  'Ex. Move iron shots from left to right across chosen target'
                )}
              </Typography>
            </Box>
          </>
        )}
        {value === DrillType.DynamicPerformance && (
          <>
            <DrillDynamicPerformanceIcon width={60} height={60} />
            <Box mt={2}>
              <Typography variant="h5" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.${value}`}>{value}</Trans>
              </Typography>
            </Box>
            <Box padding={2}>
              <Typography
                color="textSecondary"
                style={{ fontSize: 'smaller', textAlign: 'center' }}
              >
                {t(
                  `${I18N_KEY}.DynamicPerformanceCardDescription`,
                  'Ex. Take median proximity of 5 shots from 100-125 yards from the fairway'
                )}
              </Typography>
            </Box>
          </>
        )}
        <StyleDrillRadio
          id={radioId}
          checked={checked}
          value={value}
          name="drillTypeOption"
          inputProps={{ 'aria-label': value as string }}
          disabled={isDisabled}
          style={{ pointerEvents: 'none' }}
          {...rest}
        />
      </DrillTypeContainer>
    )
  })

  return (
    <>
      <Box mt={4} mb={5}>
        <HookFormTextField
          control={control}
          name="drillTitle"
          errors={errors}
          fullWidth
          label={t(`${I18N_KEY}.drillTitle`, 'Drill Title')}
        />
      </Box>
      <Box mt={4} mb={20}>
        <Label
          variant="caption"
          // color={!isValid && isDirty ? 'error' : undefined}
        >
          <Trans i18nKey={`${I18N_KEY}.drillType`}>Drill Type</Trans>
        </Label>
        <Controller
          name="drillType"
          control={control}
          defaultValue={DrillType.CoachDefined}
          render={({ onChange, value }) => (
            <RadioGroup
              aria-label="drill-type"
              row
              style={{
                gap: '1rem',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
              }}
              onChange={onChange}
            >
              {Object.values(DrillType).map((goal, index) => (
                <DrillTypeRadio
                  key={index}
                  name="drillTypeOption"
                  value={goal}
                  checked={value === goal}
                >
                  {goal}
                </DrillTypeRadio>
              ))}
            </RadioGroup>
          )}
        />
      </Box>
    </>
  )
}

export default DrillDetails
