import React, { useState, MouseEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'

import { DataRow } from 'src/components/dataDisplay/tableStyles'
import { Coach } from 'src/models/coach'
import {
  EndCell,
  IconButton,
  StyledCell,
  StyledCellCentered,
} from 'src/modules/activity/common'

const I18N_KEY = 'CoachDrillRow'

interface Props {
  drill: any
  coach: Coach
  handleDrillClick: (e: MouseEvent<HTMLTableRowElement>) => void
  handleEditClick: (e: MouseEvent<HTMLLIElement>) => void
  handleDuplicateClick: (e: MouseEvent<HTMLLIElement>) => Promise<void>
  handleDeleteClick: (e: MouseEvent<HTMLLIElement>) => void
  handleAssignToPlayerClick: (e: MouseEvent<HTMLLIElement>) => void
  handleAddToSessionClick: (e: MouseEvent<HTMLLIElement>) => void
}

const LabelStyle = styled('div')`
  padding: 0.5rem 01rem;
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.08);
  text-align: center;
`

const DrillRow: React.FC<Props> = ({
  drill,
  coach,
  handleDrillClick,
  handleEditClick,
  handleDuplicateClick,
  handleDeleteClick,
  handleAssignToPlayerClick,
  handleAddToSessionClick,
  ...props
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <DataRow
        id={drill.uuid}
        onClick={handleDrillClick}
        {...props}
        data-cy="coach-drill"
      >
        <StyledCell>
          <Typography>{drill.activityName}</Typography>
        </StyledCell>
        <StyledCell>
          {drill?.isCustom ? (
            <LabelStyle>
              <Typography>{coach.firstName + ' ' + coach.lastName}</Typography>
            </LabelStyle>
          ) : (
            <Typography style={{ color: '#757575' }}>System</Typography>
          )}
        </StyledCell>
        {drill.category ? (
          <StyledCell>
            <LabelStyle>
              <Typography>
                {t(`Enums:CategoryType.${drill.category}`)}
              </Typography>
            </LabelStyle>
          </StyledCell>
        ) : (
          <StyledCellCentered>
            <Typography>-</Typography>
          </StyledCellCentered>
        )}
        {drill?.subcategories ? (
          <StyledCell>
            <Typography>
              {drill.subcategories
                .map((subcategory: any) =>
                  t(`Enums:MetricLabels.${subcategory}`)
                )
                .join(', ')}
            </Typography>
          </StyledCell>
        ) : (
          <StyledCellCentered>
            <Typography>-</Typography>
          </StyledCellCentered>
        )}
        <StyledCell>
          <Typography>{t(`${I18N_KEY}.${drill.drillType}`, '')}</Typography>
        </StyledCell>
        <EndCell align="center">
          <IconButton
            size="small"
            color="primary"
            onClick={openMenu}
            aria-label="Options"
            data-cy="open-round-options"
          >
            <MoreIcon />
          </IconButton>
        </EndCell>
      </DataRow>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={e => {
            handleDuplicateClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.duplicate`}>Duplicate</Trans>
        </MenuItem>
        <MenuItem
          disabled={!drill.isCustom}
          onClick={e => {
            handleEditClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.edit`}>Edit</Trans>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={e => {
            handleAssignToPlayerClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.assignToPlayer`}>Assign to Player</Trans>
        </MenuItem>
        <MenuItem
          onClick={e => {
            handleAddToSessionClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.addToSession`}>Add To Session</Trans>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={e => {
            handleDeleteClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.delete`}>Delete</Trans>
        </MenuItem>
      </Menu>
    </>
  )
}

export default DrillRow
