import React from 'react'
import styled, { css } from 'styled-components'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import {
  FormControl,
  TextareaAutosizeProps,
  TextareaAutosize,
  Box,
} from '@material-ui/core'
import { Label } from '../Label'
import HelpTooltip from '../dataDisplay/HelpTooltip'

interface ExtraProps {
  label: string
  name: string
  control: Control
  errors?: FieldErrors
  defaultValue?: string
  toolTipTranslationKey?: string
  toolTipTranslationValue?: string
  toolTipTitle?: string
}

export type HookFormTextareaAutosizeProps = TextareaAutosizeProps & ExtraProps

interface ErrorProps {
  $active: boolean
}

const StyledContainer = styled(FormControl)`
  position: relative;
`

const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: 1px solid #bcbcbc;
  border-radius: 0.35rem;
  padding: 1rem;
`

const ErrorMessage = styled.span<ErrorProps>(
  ({ theme, $active }) => css`
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${$active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${$active ? -24 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const HookFormTextareaAutosize: React.FC<HookFormTextareaAutosizeProps> = ({
  name,
  label,
  errors,
  control,
  className,
  defaultValue = '',
  toolTipTranslationKey,
  toolTipTranslationValue,
  toolTipTitle,
  onChange: customOnChange,
  ...props
}) => {
  const fieldError = errors && errors[name]
  const hasError = !!fieldError

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ onChange, ...renderProps }) => (
          <StyledContainer className={className}>
            <Box display="flex">
              {label && (
                <Label $hasError={hasError} htmlFor={name}>
                  {label}
                </Label>
              )}
              {toolTipTranslationKey &&
                toolTipTranslationValue &&
                toolTipTitle && (
                  <HelpTooltip
                    translationKey={toolTipTranslationKey}
                    translationValue={toolTipTranslationValue}
                    title={toolTipTitle}
                  />
                )}
            </Box>
            <StyledTextareaAutosize
              id={name}
              onChange={e => {
                control.trigger(name)
                if (customOnChange) customOnChange(e)
                onChange(e)
              }}
              {...renderProps}
              {...props}
            />
            <ErrorMessage $active={hasError}>
              {fieldError?.message}
            </ErrorMessage>
          </StyledContainer>
        )}
      />
    </>
  )
}

export default React.memo(HookFormTextareaAutosize)
