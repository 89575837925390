import React from 'react'
import { useTranslation } from 'react-i18next'
import { Control, FieldErrors } from 'react-hook-form'

import { Box } from '@material-ui/core'
import HookFormTextareaAutosize from 'src/components/inputs/HookFormTextareaAutosize'

import CategorySelection from './CategorySelection'

const I18N_KEY = 'CompletionBased'

interface Props {
  control: Control
  errors: FieldErrors
  // isDirty: boolean
  // isValid: boolean
  // isSubmitting: boolean
}

const CompletionBased: React.FC<Props> = ({
  control,
  errors,
  // isDirty,
  // isValid,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Box mt={4} mb={5} width="100%">
        <CategorySelection control={control} errors={errors} />
      </Box>
      <Box mt={4} mb={5} width="100%">
        <HookFormTextareaAutosize
          name="description"
          label={t(`${I18N_KEY}.drillDescription`, 'Drill Description')}
          minRows={16}
          control={control}
          errors={errors}
          toolTipTranslationKey={`${I18N_KEY}`}
          toolTipTranslationValue="descriptionTooltip"
          toolTipTitle="Description of how the drill will be completed by the player"
        />
      </Box>
    </>
  )
}

export default CompletionBased
