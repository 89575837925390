import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

import LoaderButton from 'src/components/inputs/LoaderButton'

import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import { breakpoints } from 'src/utils/constants'
import { DoneIcon, StepInfo } from '../../../common'
import { AnimatePresence, motion } from 'framer-motion'
import { defaultTransition, fadeVariants } from 'src/utils/animations'
import DrillDetails from './DrillDetails'

import {
  CategoryType,
  DrillGoalType,
  DrillMeasurement,
  DrillType,
  MetricId,
  ValueGo,
} from 'src/utils/golfConstants'
import CompletionBased from './CompletionBased'
import CoachDefined from './CoachDefined'
import DrillSummary from './DrillSummary'
import { SpeedDialIcon } from '@material-ui/lab'
import DynamicPerformance from './DynamicPerformance'
import { Box } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import {
  createCoachDrill,
  drillCreateDialogOpenSelector,
  DrillRequest,
  updateCreateDialogVisibility,
} from 'src/store/drillsSlice'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import { createDrillSchema } from 'src/utils/validationSchemas'

const I18N_KEY = 'CreateDrillDialog'

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(1, 0, 1, 0)};
  `
)

const StyledDialogActions = styled(DialogActions)(
  () => css`
    justify-content: space-between;
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(0, 2)};
    }

    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(0, 5)};
    }
  `
)

export interface FormValues {
  drillTitle: string
  drillType: DrillType
  category: CategoryType | 'n/a'
  subcategory: MetricId | 'n/a'
  metric: MetricId | null
  description: string
  go: ValueGo
  goalType: any
  goal: number | null
  attempts: number
  difficulty: number
  unit: DrillMeasurement
  estimatedTime: number
  coachNotes: string
}

const CoachDrillCreateDialog: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const dialogOpen = useSelector(drillCreateDialogOpenSelector)

  const [isSubmitting, setSubmitting] = useState(false)
  const [formDrillType, setFormDrillType] = useState(DrillType.CoachDefined)
  const [formCustomOrMetric, setFormCustomOrMetric] = useState<
    'custom' | 'metric'
  >('custom')
  const [formCategory, setFormCategory] = useState('n/a')

  const validationSchema = useMemo(
    () => createDrillSchema(t, formDrillType, formCustomOrMetric, formCategory),
    [t, formDrillType, formCustomOrMetric, formCategory]
  )

  const [currentStep, setCurrentStep] = useState(0)

  const formMethods = useForm({
    shouldUnregister: false,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    context: { currentStep }, // required for validation
    defaultValues: {
      drillTitle: '',
      drillType: DrillType.CoachDefined,
      customOrMetric: 'custom',
      category: 'n/a',
      subcategory: 'n/a',
      metric: null,
      go: ValueGo.Above,
      attempts: 10,
      difficulty: 10,
      description: '',
      coachNotes: '',
      goalType: '',
      goal: null,
      unit: 'feet',
      estimatedTime: 0,
    },
  })

  const { control, handleSubmit, watch, errors, formState, reset, trigger } =
    formMethods
  const { isValid } = formState

  const selectedDrillType = watch('drillType') || null
  const drillTitle = watch('drillTitle') || ''
  const customOrMetric: 'custom' | 'metric' = watch('customOrMetric') as
    | 'custom'
    | 'metric'
  const drillCategory = watch('category') || ''
  const description = watch('description') || ''

  useEffect(() => {
    setFormDrillType(selectedDrillType)
  }, [setFormDrillType, selectedDrillType])

  useEffect(() => {
    setFormCustomOrMetric(customOrMetric)
  }, [setFormCustomOrMetric, customOrMetric])

  useEffect(() => {
    setFormCategory(drillCategory)
  }, [setFormCategory, drillCategory])

  useEffect(() => {
    reset(
      {
        drillTitle, // Preserve drillTitle
        drillType: selectedDrillType, // Preserve drillType
        customOrMetric,
        category: 'n/a',
        subcategory: 'n/a',
        metric: null,
        go: ValueGo.Above,
        attempts: 10,
        difficulty: 10,
        description: '',
        coachNotes: '',
        goalType: '',
        goal: null,
        unit: 'feet',
        estimatedTime: 0,
      },
      {
        errors: true,
        isValid: true,
      }
    )
  }, [drillTitle, selectedDrillType, customOrMetric, reset, trigger])

  const closeDialog = () => {
    reset({
      drillTitle: '',
      drillType: DrillType.CoachDefined,
    })
    setCurrentStep(0)
    dispatch(updateCreateDialogVisibility({ isOpen: false }))
  }

  const isStepDisabled = (): boolean | undefined => {
    if (currentStep === 0) {
      trigger('drillTitle')
      return isValid
    }
    if (currentStep === 1) {
      if (
        currentStep === 1 &&
        selectedDrillType === DrillType.CompletionBased
      ) {
        trigger('description')
      }
      if (currentStep === 1 && selectedDrillType === DrillType.CoachDefined) {
        if (customOrMetric === 'metric') {
          trigger('category')
          trigger('subcategory')
        }
      }
      return isValid
    }
    return false
  }

  const handleFormSubmission = handleSubmit(
    async ({
      drillTitle,
      goalType,
      category,
      subcategory,
      metric,
      go,
      ...values
    }: FormValues) => {
      try {
        setSubmitting(true)
        const selectedCategory = category === 'n/a' ? null : category
        const selectedSubcategory = subcategory === 'n/a' ? null : [subcategory]

        const drillPayload: DrillRequest = {
          ...values,
          activityName: drillTitle,
          activityId: null,
          isCustom: true,
          category: selectedCategory,
          subcategories: selectedSubcategory,
          metricId: metric,
          isOutOfTen: goalType === DrillGoalType.PuttsMade,
        }
        if (
          values.drillType === DrillType.CoachDefined &&
          customOrMetric === 'custom'
        ) {
          drillPayload.go = go
        }
        // Optional fields
        if (goalType) {
          drillPayload.goalType = goalType
        }
        await dispatch(createCoachDrill(drillPayload))
        dispatch(
          openToast(
            getSuccessToast(t(`${I18N_KEY}.drillCreated`, 'Drill Created'))
          )
        )
      } catch (error) {
        dispatch(
          openToast(
            getErrorToast(
              t(
                `${I18N_KEY}.createDrillError`,
                'Error creating Drill. Please try it later'
              )
            )
          )
        )
      } finally {
        reset()
        setCurrentStep(0)
        setSubmitting(false)
        closeDialog()
      }
    }
  )

  const steps = [
    t(`${I18N_KEY}.step1`, 'Drill Details'),
    t(`${I18N_KEY}.step2`, 'Exercise & Goals'),
    t(`${I18N_KEY}.step3`, 'Summary'),
  ]

  const handleStepClick = () => {
    if (currentStep <= 2) {
      setCurrentStep(currentStep + 1)
    }
  }

  const generateStepClick = (index: number) => () => {
    switch (index) {
      case 0:
        setCurrentStep(0)
        break
      case 1:
        setCurrentStep(1)
        break
      case 2:
        setCurrentStep(2)
        break
      default:
        setCurrentStep(0)
        break
    }
  }

  const generateStepDisabled = (index: number) => {
    switch (index) {
      case 0:
        return false
      case 1:
        return drillTitle.length <= 0
      case 2:
        if (
          selectedDrillType === DrillType.CompletionBased &&
          watch('description').length <= 0
        ) {
          return true
        }
        if (selectedDrillType === DrillType.CoachDefined) {
          const isCustom = watch('customOrMetric') === 'custom'
          if (isCustom) {
            if (description.length <= 0) return true
          } else {
            if (watch('goalType') === '') return true
          }
        }
        if (selectedDrillType === DrillType.DynamicPerformance) {
          if (watch('goalType') === '') return true
        }
        return false
      default:
        return false
    }
  }

  return (
    <Dialog maxWidth="lg" open={dialogOpen}>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => closeDialog()}
          style={{ position: 'absolute', top: 8, right: 8, color: 'gray' }}
        >
          <Close />
        </IconButton>
        <Title variant="h4">
          <Trans i18nKey={`${I18N_KEY}.title`}>Create New Drill</Trans>
        </Title>
        <ButtonContainer>
          {steps.map((step, index) => {
            const completed = index < currentStep
            return (
              <StepInfo
                key={index}
                color="primary"
                $completed={completed}
                $selected={index === currentStep}
                onClick={generateStepClick(index)}
                disabled={generateStepDisabled(index)}
              >
                {completed ? <DoneIcon /> : `${index + 1}. `} {step}
              </StepInfo>
            )
          })}
        </ButtonContainer>
        <Divider />
        <>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              exit="exit"
              initial="enter"
              animate="visible"
              variants={fadeVariants}
              key={steps[currentStep]}
              transition={defaultTransition}
            >
              <Box style={{ minWidth: '860px' }}>
                {currentStep >= 0 && (
                  <section
                    style={{ display: currentStep === 0 ? 'block' : 'none' }}
                  >
                    <DrillDetails
                      control={control}
                      errors={errors}
                      // isDirty={isDirty}
                    />
                  </section>
                )}
                {currentStep >= 1 && (
                  <section
                    style={{ display: currentStep === 1 ? 'block' : 'none' }}
                  >
                    {selectedDrillType === DrillType.CompletionBased && (
                      <CompletionBased
                        control={control}
                        errors={errors}
                        // isDirty={isDirty}
                        // isValid={isValid}
                      />
                    )}
                    {selectedDrillType === DrillType.CoachDefined && (
                      <CoachDefined
                        control={control}
                        errors={errors}
                        // isDirty={isDirty}
                        // isValid={isValid}
                      />
                    )}
                    {selectedDrillType === DrillType.DynamicPerformance && (
                      <DynamicPerformance
                        control={control}
                        errors={errors}
                        // isDirty={isDirty}
                        // isValid={isValid}
                      />
                    )}
                  </section>
                )}
                {currentStep >= 2 && (
                  <section
                    style={{ display: currentStep === 2 ? 'block' : 'none' }}
                  >
                    <DrillSummary
                      control={control}
                      // errors={errors}
                      // isDirty={isDirty}
                      // isValid={isValid}
                    />
                  </section>
                )}
              </Box>
            </motion.div>
          </AnimatePresence>
        </>
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        <Box style={{ display: 'flex', gap: '1rem' }}>
          <Button onClick={closeDialog}>
            <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
          </Button>
          <Button onClick={closeDialog} variant="outlined" color="primary">
            <Trans i18nKey={`${I18N_KEY}.saveDraftButtonLabel`}>
              Save Draft
            </Trans>
          </Button>
        </Box>
        {(currentStep === 0 || currentStep === 1) && (
          <LoaderButton
            loading={false}
            disabled={!isStepDisabled()}
            onClick={handleStepClick}
            variant="contained"
            color="primary"
          >
            <Trans i18nKey={`${I18N_KEY}.continueButton`}>Continue</Trans>
            <ArrowForward />
          </LoaderButton>
        )}
        {currentStep === 2 && (
          <LoaderButton
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            onClick={handleFormSubmission}
            variant="contained"
            color="primary"
          >
            <SpeedDialIcon style={{ fontSize: 'medium' }} />
            <Typography style={{ fontWeight: '500' }}>
              {t(`${I18N_KEY}.createDrillAction`, 'Create Drill')}
            </Typography>
          </LoaderButton>
        )}
      </StyledDialogActions>
    </Dialog>
  )
}

export default CoachDrillCreateDialog
