import React from 'react'

interface Props {
  width?: number
  height?: number
}

const DrillDynamicPerformanceIcon: React.FC<Props> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1622 0.600586V12.7087C21.1622 16.9868 16.3378 19.9736 12.0811 23.0006C7.82432 19.9736 3 16.9868 3 12.7087V0.600586H21.1622Z"
        fill="#2549BF"
        fillOpacity="0.87"
      />
      <path
        d="M17.7571 4.00586V11.9825C17.7571 14.8008 14.7419 16.7685 12.0814 18.7626C9.42096 16.7685 6.40576 14.8008 6.40576 11.9825V4.00586H17.7571Z"
        fill="#2549BF"
        fillOpacity="0.87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4599 2.30371V11.983C19.4599 14.0502 18.3426 15.6566 17.1249 16.8822C16.1243 17.8894 14.867 18.819 13.7505 19.6446C13.5279 19.8091 13.311 19.9695 13.1027 20.1256L12.0815 20.8911L11.0603 20.1256C10.852 19.9696 10.6351 19.8091 10.4125 19.6446C9.29599 18.819 8.03872 17.8894 7.03807 16.8822C5.82039 15.6566 4.70312 14.0502 4.70312 11.983V2.30371H19.4599ZM17.7572 4.00641H6.40583V11.983C6.40583 14.5597 8.92613 16.4253 11.3915 18.2502C11.6227 18.4214 11.8534 18.5922 12.0815 18.7632C12.3096 18.5922 12.5403 18.4214 12.7716 18.2502C15.2369 16.4253 17.7572 14.5597 17.7572 11.983V4.00641Z"
        fill="white"
      />
      <mask
        id="mask0_40000077_20167"
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="12"
        height="12"
      >
        <rect x="6" y="4" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_40000077_20167)">
        <path
          d="M9.75 9.5C9.26667 9.5 8.85417 9.32917 8.5125 8.9875C8.17083 8.64583 8 8.23333 8 7.75C8 7.26667 8.17083 6.85417 8.5125 6.5125C8.85417 6.17083 9.26667 6 9.75 6C10.2333 6 10.6458 6.17083 10.9875 6.5125C11.3292 6.85417 11.5 7.26667 11.5 7.75C11.5 8.23333 11.3292 8.64583 10.9875 8.9875C10.6458 9.32917 10.2333 9.5 9.75 9.5ZM9.75 8.5C9.95833 8.5 10.1354 8.42708 10.2812 8.28125C10.4271 8.13542 10.5 7.95833 10.5 7.75C10.5 7.54167 10.4271 7.36458 10.2812 7.21875C10.1354 7.07292 9.95833 7 9.75 7C9.54167 7 9.36458 7.07292 9.21875 7.21875C9.07292 7.36458 9 7.54167 9 7.75C9 7.95833 9.07292 8.13542 9.21875 8.28125C9.36458 8.42708 9.54167 8.5 9.75 8.5ZM14.25 14C13.7667 14 13.3542 13.8292 13.0125 13.4875C12.6708 13.1458 12.5 12.7333 12.5 12.25C12.5 11.7667 12.6708 11.3542 13.0125 11.0125C13.3542 10.6708 13.7667 10.5 14.25 10.5C14.7333 10.5 15.1458 10.6708 15.4875 11.0125C15.8292 11.3542 16 11.7667 16 12.25C16 12.7333 15.8292 13.1458 15.4875 13.4875C15.1458 13.8292 14.7333 14 14.25 14ZM14.25 13C14.4583 13 14.6354 12.9271 14.7812 12.7812C14.9271 12.6354 15 12.4583 15 12.25C15 12.0417 14.9271 11.8646 14.7812 11.7188C14.6354 11.5729 14.4583 11.5 14.25 11.5C14.0417 11.5 13.8646 11.5729 13.7188 11.7188C13.5729 11.8646 13.5 12.0417 13.5 12.25C13.5 12.4583 13.5729 12.6354 13.7188 12.7812C13.8646 12.9271 14.0417 13 14.25 13ZM8.35 13.65C8.25833 13.5583 8.2125 13.4417 8.2125 13.3C8.2125 13.1583 8.25833 13.0417 8.35 12.95L14.95 6.35C15.0417 6.25833 15.1583 6.2125 15.3 6.2125C15.4417 6.2125 15.5583 6.25833 15.65 6.35C15.7417 6.44167 15.7875 6.55833 15.7875 6.7C15.7875 6.84167 15.7417 6.95833 15.65 7.05L9.05 13.65C8.95833 13.7417 8.84167 13.7875 8.7 13.7875C8.55833 13.7875 8.44167 13.7417 8.35 13.65Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default React.memo(DrillDynamicPerformanceIcon)
