import { ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  PracticeTimeFilter,
  CategoryFilter,
  GoalFilter,
  AuthorFilter,
} from 'src/utils/constants'
import {
  getPracticeSummary,
  getPracticeTimeSpent,
  practiceFiltersSelector,
  updateFilters,
} from 'src/store/practiceSlice'
import { getCoachDrills } from 'src/store/drillsSlice'
import { getCoachSessions } from 'src/store/sessionSlice'

const timeFilterValues = Object.values(PracticeTimeFilter)
const categoryFilterValues = Object.values(CategoryFilter)
const goalFilterValues = Object.values(GoalFilter)
const authorFilterValues = Object.values(AuthorFilter)

const usePracticeFilters = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { timeFilter, categoryFilter, goalFilter, authorFilter } = useSelector(
    practiceFiltersSelector
  )

  const timeFilterOptions = useMemo(
    () =>
      timeFilterValues.map(time => ({
        value: time,
        label: t(`Enums:TimeFilter.${time}`),
      })),
    [t]
  )

  const categoryFilterOptions = useMemo(
    () =>
      categoryFilterValues.map(category => ({
        value: category,
        label: t(`Enums:CategoryType.${category}`),
      })),
    [t]
  )

  const goalFilterOptions = useMemo(
    () =>
      goalFilterValues.map(goal => ({
        value: goal,
        label: t(`Enums:GoalFilter.${goal}`),
      })),
    [t]
  )

  const authorFilterOptions = useMemo(
    () =>
      authorFilterValues.map(author => ({
        value: author,
        label: t(`Enums:AuthorFilter.${author}`),
      })),
    [t]
  )

  const handleTimeSelection = (event: ChangeEvent<{ value: unknown }>) => {
    const timeFilter = event.target.value as PracticeTimeFilter

    dispatch(updateFilters({ timeFilter }))
    // If custom is selected, fire dispatch once custom dates are selected
    if (timeFilter !== PracticeTimeFilter.Custom) {
      dispatch(getPracticeTimeSpent())
      dispatch(getPracticeSummary())
    }
  }

  const handleCategorySelection = (event: ChangeEvent<{ value: unknown }>) => {
    const categoryFilter = event.target.value as CategoryFilter
    dispatch(updateFilters({ categoryFilter }))
    dispatch(getCoachDrills())
  }

  const handleGoalSelection = (event: ChangeEvent<{ value: unknown }>) => {
    const goalFilter = event.target.value as GoalFilter
    dispatch(updateFilters({ goalFilter }))
    dispatch(getCoachDrills())
  }

  const handleAuthorSelection = (event: ChangeEvent<{ value: unknown }>) => {
    const authorFilter = event.target.value as AuthorFilter
    dispatch(updateFilters({ authorFilter }))
    dispatch(getCoachDrills())
    dispatch(getCoachSessions())
  }

  const handleSearchTermChange = (searchTerm: string) => {
    dispatch(updateFilters({ searchTermFilter: searchTerm }))
    dispatch(getCoachDrills())
    dispatch(getCoachSessions())
  }

  const getTimeFilterClick = (timeFilter: PracticeTimeFilter) => () => {
    dispatch(updateFilters({ timeFilter }))
    dispatch(getPracticeTimeSpent())
    dispatch(getPracticeSummary())
  }

  return {
    timeFilter,
    categoryFilter,
    goalFilter,
    timeFilterOptions,
    categoryFilterOptions,
    goalFilterOptions,
    authorFilterOptions,
    authorFilter,
    getTimeFilterClick,
    handleTimeSelection,
    handleCategorySelection,
    handleGoalSelection,
    handleAuthorSelection,
    handleSearchTermChange,
  }
}

export default usePracticeFilters
