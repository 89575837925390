import * as Yup from 'yup'
import { TFunction } from 'i18next'

import { dateTransform } from 'src/utils/helpers'
import { CategoryFilter, Frequency, Gender } from 'src/utils/constants'
import { DrillType } from './golfConstants'

const I18N_KEY = 'ValidationSchemas'

export const createSignInSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    password: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
      ),
  })

export const createSignInProviderSchema = (t: TFunction) =>
  Yup.object().shape({
    providerCode: Yup.string().required(t(`${I18N_KEY}.providerCode`, '')),
  })

export const createRegisterSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    phoneNumber: Yup.string().required(
      t(`${I18N_KEY}.phoneNumberRequired`, 'Please enter a valid phone number')
    ),
    password: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
      ),
    confirmPassword: Yup.string()
      .required(
        t(`${I18N_KEY}.passwordConfirmRequired`, 'Please confirm the password')
      )
      .oneOf(
        [Yup.ref('password'), null],
        t(`${I18N_KEY}.passwordsMustMatch`, 'Passwords must match')
      ),
    firstName: Yup.string().required(
      t(
        `${I18N_KEY}.missingFirstNameErrorMessage`,
        'Please enter your first name'
      )
    ),
    lastName: Yup.string().required(
      t(
        `${I18N_KEY}.missingLastNameErrorMessage`,
        'Please enter your last name'
      )
    ),
    brokeredId: Yup.string().notRequired(),
    recaptchaToken: Yup.string().notRequired(),
  })

export const createForgotPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
  })

export const createResetPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required(t(`${I18N_KEY}.passwordRequired`, 'Please enter a password'))
      .min(
        8,
        t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters')
      ),
    passwordConfirm: Yup.string()
      .required(
        t(`${I18N_KEY}.passwordConfirmRequired`, 'Please confirm the password')
      )
      .oneOf(
        [Yup.ref('newPassword'), null],
        t(`${I18N_KEY}.passwordsMustMatch`, 'Passwords must match')
      ),
    token: Yup.string().required(
      t(
        `${I18N_KEY}.tokenRequired`,
        'Please enter a valid password reset token'
      )
    ),
  })

export const createProfileSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(
      t(
        `${I18N_KEY}.missingFirstNameErrorMessage`,
        'Please enter your first name'
      )
    ),
    lastName: Yup.string().required(
      t(
        `${I18N_KEY}.missingLastNameErrorMessage`,
        'Please enter your last name'
      )
    ),
    gender: Yup.string().required(
      t(`${I18N_KEY}.missingGenderErrorMessage`, 'Please select your gender')
    ),
    dob: Yup.date()
      .transform(dateTransform)
      .required(
        t(
          `${I18N_KEY}.invalidBirthDateErrorMessage`,
          'Please enter a valid birth date'
        )
      ),
  })

export const createChangePasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
    ),
    newPassword: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.newPasswordRequired`, 'Please enter your new password')
      ),
    confirmPassword: Yup.string()
      .required(
        t(`${I18N_KEY}.confirmPasswordRequired`, 'Please confirm your password')
      )
      .oneOf(
        [Yup.ref('newPassword')],
        t(`${I18N_KEY}.passwordMatch`, 'Passwords must match')
      ),
  })

export const createBillingAddressSchema = (t: TFunction) =>
  Yup.object().shape({
    fullName: Yup.string().required(
      t(
        `${I18N_KEY}.missingFirstNameErrorMessage`,
        'Please enter the first name'
      )
    ),
    address: Yup.string().required(
      t(`${I18N_KEY}.missingAddressErrorMessage`, 'Please enter the address')
    ),
    city: Yup.string().required(
      t(`${I18N_KEY}.missingCityErrorMessage`, 'Please enter the city')
    ),
    postcode: Yup.string().required(
      t(
        `${I18N_KEY}.missingPostcodeErrorMessage`,
        'Please enter the Post/ZIP code'
      )
    ),
  })

export const createPaymentSchema = (t: TFunction) =>
  createBillingAddressSchema(t).concat(
    Yup.object().shape({
      nameOnCard: Yup.string().required(
        t(`${I18N_KEY}.missingPaymentFullName`, 'Please enter your full name')
      ),
    })
  )

export const createCoachAuthSchema = (t: TFunction) =>
  Yup.object().shape({
    lastName: Yup.string().required(
      t(`${I18N_KEY}.lastNameRequired`, 'Enter your last name')
    ),
    firstName: Yup.string().required(
      t(`${I18N_KEY}.firstNameRequired`, 'Enter your first name')
    ),
    gender: Yup.mixed()
      .oneOf(Object.values(Gender))
      .required(t(`${I18N_KEY}.genderRequired`, 'Please select your gender')),
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    password: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
      ),
  })

export const createInviteFormSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter an email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    recaptchaToken: Yup.string().notRequired(),
  })

export const createDrillSchema = (
  t: TFunction,
  drillType: DrillType,
  customOrMetric: 'custom' | 'metric',
  category: string
) =>
  Yup.object().shape({
    drillType: Yup.mixed<DrillType>()
      .oneOf(Object.values(DrillType))
      .required(),
    drillTitle: Yup.string().required(
      t(`${I18N_KEY}.drillTitle`, 'Drill name is required')
    ),
    description: Yup.string().when([], (schema, { context }) => {
      if (
        context?.currentStep >= 1 &&
        drillType === DrillType.CompletionBased
      ) {
        return schema.required(
          t(
            `${I18N_KEY}.invalidDrillDescription`,
            'Drill must have a description'
          )
        )
      }
      return schema.notRequired()
    }),
    category: Yup.mixed<CategoryFilter>().when([], (schema, { context }) => {
      if (
        context?.currentStep === 1 &&
        drillType === DrillType.CoachDefined &&
        customOrMetric === 'metric'
      ) {
        return schema
          .required(
            t(
              `${I18N_KEY}.drillCategoryRequired`,
              'A metric based drill must have a category'
            )
          )
          .test(
            'n/a',
            'N/A is not a valid required option for category',
            (value: string) => !/n\/a/i.test(value || '')
          )
      }
      if (
        context?.currentStep === 1 &&
        drillType === DrillType.DynamicPerformance
      ) {
        return schema
          .required(
            t(
              `${I18N_KEY}.drillCategoryDynamicRequired`,
              'A dynamic performance drill must have a sub-category'
            )
          )
          .test(
            'n/a',
            'N/A is not a valid required option for category',
            (value: string) => !/n\/a/i.test(value || '')
          )
      }
      return schema.notRequired()
    }),
    subcategory: Yup.string().when([], (schema, { context }) => {
      if (
        context?.currentStep === 1 &&
        drillType === DrillType.CoachDefined &&
        customOrMetric === 'metric'
      ) {
        return schema
          .required(
            t(
              `${I18N_KEY}.drillSubcategoryRequired`,
              'A metric based drill must have a sub-category'
            )
          )
          .test(
            'n/a',
            'N/A is not a valid required option for subcategory',
            (value: string) => !/n\/a/i.test(value || '')
          )
      }
      if (
        context?.currentStep === 1 &&
        drillType === DrillType.DynamicPerformance
      ) {
        return schema
          .required(
            t(
              `${I18N_KEY}.drillSubcategoryDynamicRequired`,
              'A dynamic performance drill must have a sub-category'
            )
          )
          .test(
            'n/a',
            'N/A is not a valid required option for category',
            (value: string) => !/n\/a/i.test(value || '')
          )
      }
      return schema.notRequired()
    }),
    metric: Yup.string()
      .nullable()
      .notRequired()
      .when([], (schema, { context }) => {
        if (
          context?.currentStep >= 1 &&
          drillType === DrillType.CoachDefined &&
          customOrMetric === 'metric'
        ) {
          return schema.required(
            t(
              `${I18N_KEY}.drillMetricRequired`,
              'A metric based drill must have a metric'
            )
          )
        }
        if (
          context?.currentStep >= 1 &&
          drillType === DrillType.DynamicPerformance
        ) {
          return schema.required(
            t(
              `${I18N_KEY}.drillMetricDynamicRequired`,
              'A dynamic performance drill must have a metric'
            )
          )
        }
        return schema
      }),
    goal: Yup.number()
      .transform((value, originalValue) => {
        if (originalValue == null || originalValue.trim() === '') return null
        return Number(originalValue)
      })
      .nullable()
      .notRequired()
      .when([], (schema, { context }) => {
        if (
          context?.currentStep >= 1 &&
          drillType === DrillType.CoachDefined &&
          customOrMetric === 'metric' &&
          category === 'putt'
        ) {
          return schema
            .max(
              10,
              t(
                `${I18N_KEY}.puttsGoalLength`,
                'If a coach has selected a metric that has putts made as the unit, the goal must be less than the number of attempts'
              )
            )
            .required()
        }
        if (context?.currentStep >= 1 && drillType === DrillType.CoachDefined) {
          return schema.required()
        }
        return schema
      }),
  })

export const assignToPlayerSchema = (t: TFunction, frequency: Frequency) =>
  Yup.object().shape({
    startDate: Yup.date()
      .nullable()
      .notRequired()
      .when([], (schema, { context }) => {
        if (context?.currentStep >= 1) {
          return schema.required()
        }
      }),
    endDate: Yup.date()
      .nullable()
      .notRequired()
      .when('startDate', (startDate, schema, { context }) => {
        if (context?.currentStep >= 1 && frequency !== Frequency.Once) {
          return schema.test(
            'end-date-after-start-date',
            t('End date must be after the start date'),
            (endDate: Date) => {
              if (startDate && endDate && startDate > endDate) {
                return false
              }
              return true
            }
          )
        }
        return schema
      }),
    monthDay: Yup.number()
      .transform((value, originalValue) => {
        if (originalValue == null || originalValue.trim() === '') return null
        return Number(originalValue)
      })
      .nullable()
      .notRequired()
      .when('showMonthDay', (showMonthDay, schema, { context }) => {
        if (
          showMonthDay &&
          context?.currentStep >= 1 &&
          frequency === Frequency.Monthly
        ) {
          console.error(
            t(`${I18N_KEY}.MonthMaxDays`, 'A month cannot exceed 31 days')
          )
          return schema.max(31, '')
        }
        return schema
      }),
    repeatsEvery: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' || originalValue == null
          ? null
          : Number(originalValue)
      ),
    occurrences: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' || originalValue == null
          ? null
          : Number(originalValue)
      ),
  })
