import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CoachSession } from 'src/utils/golfConstants'
import { Box, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import styled, { css } from 'styled-components'
import { formatTime } from '../util'

const I18N_KEY = 'SessionDetailsSummary'

interface Props {
  session: CoachSession
  sectionCount: number
  drillCount: number
  totalDuration: number
}

const StyledBox = styled(Box)(
  () => css`
    display: flex;
    gap: 1rem;
  `
)

const SessionDetailsSummary: React.FC<Props> = ({
  session,
  sectionCount,
  drillCount,
  totalDuration,
}) => {
  const { t } = useTranslation()

  const formatSubcategory = (subcategory: any) => {
    return subcategory === 'n/a'
      ? t(`${I18N_KEY}.n/a`)
      : t(`Enums:MetricLabels.${subcategory}`)
  }
  return (
    <>
      <Typography variant="h4">
        <Trans i18nKey={`${I18N_KEY}.sessionDetails`}>Session Details</Trans>
      </Typography>
      <Box mt={4} display="flex" style={{ gap: '2rem' }}>
        <StyledBox width="40%" flexDirection="column">
          <Typography variant="h5">
            <Trans i18nKey={`${I18N_KEY}.description`}>Description</Trans>
          </Typography>
          <Box width="100%" display="grid">
            <Typography
              variant="body1"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
              }}
            >
              {session.description ||
                t(`${I18N_KEY}.n/a`, { defaultValue: 'N/A' })}
            </Typography>
          </Box>
          <Typography variant="h5">
            <Trans i18nKey={`${I18N_KEY}.details`}>Details</Trans>
          </Typography>
          <Box>
            <StyledBox>
              <Typography variant="body1" style={{ width: '4.5rem' }}>
                <Trans i18nKey={`${I18N_KEY}.Sections`}>Sections</Trans>
              </Typography>
              <Typography variant="body2">{sectionCount}</Typography>
            </StyledBox>
            <StyledBox>
              <Typography variant="body1" style={{ width: '4.5rem' }}>
                <Trans i18nKey={`${I18N_KEY}.drills`}>Drills</Trans>
              </Typography>
              <Typography variant="body2">{drillCount}</Typography>
            </StyledBox>
            <StyledBox>
              <Typography variant="body1" style={{ width: '4.5rem' }}>
                <Trans i18nKey={`${I18N_KEY}.duration`}>Est. Time</Trans>
              </Typography>
              <Typography variant="body2">
                {formatTime(
                  totalDuration,
                  t(`${I18N_KEY}.hr`, { defaultValue: 'hr' }),
                  t(`${I18N_KEY}.min`, { defaultValue: 'min' })
                )}
              </Typography>
            </StyledBox>
          </Box>
        </StyledBox>
        <Divider orientation="vertical" flexItem />
        <Box width="60%">
          <Typography variant="h5">
            <Trans i18nKey={`${I18N_KEY}.sessionOverview`}>
              Session Overview
            </Trans>
          </Typography>
          <Box mt={2}>
            {session.sections.map(section => (
              <Box key={section.uuid}>
                <Typography variant="body2">{section.name}</Typography>
                {section.drills.map((drill, index) => (
                  <StyledBox key={drill.uuid} ml={2}>
                    <Divider orientation="vertical" flexItem />
                    <StyledBox>
                      <Typography variant="body1" style={{ width: '1rem' }}>
                        {index + 1}
                      </Typography>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1">
                          {drill.activityName}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          style={{ fontSize: 'smaller', marginTop: '-0.5rem' }}
                        >
                          {formatSubcategory(drill.subcategories?.[0])}
                        </Typography>
                      </Box>
                    </StyledBox>
                  </StyledBox>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SessionDetailsSummary
