import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'

import MuiSpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import MuiSpeedDialAction from '@material-ui/lab/SpeedDialAction'

import { breakpoints } from 'src/utils/constants'
import CirclesIcon from '../../assets/svgComponents/CirclesIcon'
import { updateCreateDialogVisibility as handleCreateDrillDialogVisibility } from 'src/store/drillsSlice'

const I18N_KEY = 'CoachFloatingButton'

const SpeedDial = styled(MuiSpeedDial)(
  ({ theme }) => css`
    right: 24px;
    bottom: 40px;
    position: fixed;

    .MuiFab-primary {
      width: 60px;
      height: 60px;
      box-shadow: ${theme.shadows[2]};
    }

    .MuiSpeedDialAction-staticTooltipLabel {
      font-weight: 500;
      border-radius: 6px;
      background-color: #fff;
      padding: ${theme.spacing(1)}px;
      box-shadow: ${theme.shadows[2]};
      color: ${theme.palette.primary.main};
      font-size: ${theme.typography.pxToRem(14)};
    }

    .hidden {
      display: none;
    }

    #AddCoachPracticeActivity-action-0-label {
      width: 9em;
    }

    #AddCoachPracticeActivity-action-1-label {
      width: 10.5em;
    }

    #AddCoachPracticeActivity-action-2-label {
      width: 10.5em;
    }

    ${theme.breakpoints.down(breakpoints.intercom)} {
      bottom: 20px;
    }
  `
)

const SpeedDialAction = styled(MuiSpeedDialAction)(
  ({ theme }) => css`
    width: 44px;
    height: 44px;
    color: #fff;
    box-shadow: ${theme.shadows[2]};
    background-color: ${theme.palette.primary.main};

    :hover {
      background-color: ${theme.palette.primary.light};
    }
  `
)

const CoachFloatingActions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  // const coachPermissions = useSelector(coachPermissionsSelector)
  // const { userType } = useSelector(userTypeInfoSelector)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose = () => {
    setIsOpen(false)
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const openCreateDrillDialog = () => {
    dispatch(handleCreateDrillDialogVisibility({ isOpen: true }))
    handleClose()
  }

  const openCreateSessionDialog = async () => {
    alert('Not yet implemented')
    // dispatch(handleCreateSessionDialogVisibility({ isOpen: true }))
  }

  const assignDrillSessionDialog = async () => {
    alert('Not yet implemented')
    // dispatch(handleCreateSessionDialogVisibility({ isOpen: true }))
  }

  // TODO: We might restrict feature access before release
  // const isCoachEligibleForDiscussionDocument = (): boolean => {
  //   if (userType === UserType.Coach) {
  //     return coachPermissions.some(
  //       permission =>
  //         permission.feature === FeatureType.DiscussionDocument &&
  //         permission.write
  //     )
  //   }
  //   return false
  // }

  return (
    <>
      <SpeedDial
        open={isOpen}
        direction="up"
        onClick={toggleOpen}
        onClose={handleClose}
        icon={<SpeedDialIcon />}
        ariaLabel={t(
          `${I18N_KEY}.addCoachPracticeActivity`,
          'Add Coach Practice Activity'
        )}
        data-cy="add-coach-practice-activity"
      >
        <SpeedDialAction
          tooltipOpen
          icon={<CirclesIcon />}
          onClick={openCreateDrillDialog}
          tooltipTitle={t(`${I18N_KEY}.addDrill`, 'Create New Drill')}
          data-cy="create-drill"
        />
        <SpeedDialAction
          tooltipOpen
          onClick={openCreateSessionDialog}
          icon={<CirclesIcon />}
          tooltipTitle={t(`${I18N_KEY}.addSession`, 'Create New Session')}
          data-cy="create-session"
        />
        <SpeedDialAction
          tooltipOpen
          onClick={assignDrillSessionDialog}
          icon={<CirclesIcon />}
          tooltipTitle={t(
            `${I18N_KEY}.assignDrillSession`,
            'Assign Drill/Session'
          )}
          data-cy="assign-drill-session"
        />
      </SpeedDial>
    </>
  )
}

export default CoachFloatingActions
