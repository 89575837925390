import React, { useEffect, useState, MouseEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css, StyledProps } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { DataRow, TitleCell } from 'src/components/dataDisplay/tableStyles'
import { Coach } from 'src/models/coach'
import { EndCell, StyledCell } from 'src/modules/activity/common'
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core'
import TeeGolfBall from 'src/assets/svgComponents/TeeGolfBall'
import { CoachSession } from 'src/utils/golfConstants'
import { formatTime } from '../util'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import SessionDetailsSummary from './SessionDetailsSummary'
import LoaderButton from 'src/components/inputs/LoaderButton'

const I18N_KEY = 'CoachSessionRow'

interface Props {
  session: CoachSession
  coach: Coach
  // handleViewDetailsClick: (e: MouseEvent<HTMLTableRowElement>) => void
  handleAssignToPlayerClick: (e: MouseEvent<HTMLButtonElement>) => void
}

interface CollapseProps {
  open?: boolean
}

const LabelStyle = styled('div')`
  padding: 0.5rem 01rem;
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.08);
  text-align: center;
`

const IconContainer = styled.div<{ $draft: boolean }>(
  ({ theme, $draft }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    min-width: 35px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: ${$draft
      ? theme.palette.background.light
      : theme.palette.primary.light};

    svg {
      width: 90%;
      margin-top: ${theme.spacing(0.5)}px;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `
)

const MoreIcon = styled(({ open, ...props }) => (
  <KeyboardArrowDownIcon {...props} />
))(
  ({ theme, open }: StyledProps<CollapseProps>) => css`
    transition: transform 0.2s ease-out;
    color: ${theme.palette.primary.main};
    font-size: ${theme.typography.pxToRem(20)};
    transform: rotate(${open ? '180deg' : '0'});
  `
)

const StyledRow = styled(({ open, ...props }) => <TableRow {...props} />)(
  ({ theme, open = true }: StyledProps<CollapseProps>) => css`
    border-bottom: ${open ? '2px solid' : 'none'};
    border-color: ${theme.palette.background.xlight};
    background-color: ${theme.palette.background.xlight};
  `
)

const DataCell = styled(TableCell)(
  ({ theme }) => css`
    padding-top: 0;
    padding-bottom: 0;
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 0, 1, 1)};
    }
  `
)

const SessionRow: React.FC<Props> = ({
  session,
  coach,
  // handleViewDetailsClick,
  handleAssignToPlayerClick,
  ...props
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [sectionCount, setSectionCount] = useState(0)
  const [drillCount, setDrillCount] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)

  useEffect(() => {
    setSectionCount(session.sections.length)
    const { totalDrills, estimatedTime } = session.sections.reduce(
      (acc, section) => {
        const drills = Object.values(section.drills)
        acc.totalDrills += drills.length
        acc.estimatedTime += drills.reduce(
          (sum, drill) => sum + (drill.estimatedTime || 0),
          0
        )
        return acc
      },
      { totalDrills: 0, estimatedTime: 0 }
    )
    setDrillCount(totalDrills)
    setTotalDuration(estimatedTime)
  }, [session])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleViewSession = () => {
    alert('Not yet implemented')
  }

  const sessionName =
    session.sessionType === 'session'
      ? session.name
      : session.sections?.[0]?.drills?.[0]?.activityName || session.name

  return (
    <>
      <DataRow
        id={session.uuid}
        // onClick={handleDrillClick}
        {...props}
        data-cy="coach-session"
      >
        <TitleCell>
          <Box display="flex" alignItems="center">
            <IconContainer $draft={false}>
              <TeeGolfBall />
            </IconContainer>
            <TextContainer>
              <Typography>{sessionName}</Typography>
            </TextContainer>
          </Box>
        </TitleCell>
        <StyledCell>
          {session?.isCustom ? (
            <LabelStyle>
              <Typography>{coach.firstName + ' ' + coach.lastName}</Typography>
            </LabelStyle>
          ) : (
            <Typography style={{ color: '#757575' }}>System</Typography>
          )}
        </StyledCell>
        <StyledCell align="center">
          <Typography>{drillCount}</Typography>
        </StyledCell>
        <StyledCell align="center">
          <Typography>
            {formatTime(
              totalDuration,
              t(`${I18N_KEY}.hr`, { defaultValue: 'hr' }),
              t(`${I18N_KEY}.min`, { defaultValue: 'min' })
            )}
          </Typography>
        </StyledCell>
        <EndCell align="center">
          <IconButton
            color="primary"
            onClick={toggleOpen}
            aria-label={`expand session ${session.uuid} row`}
          >
            <MoreIcon open={open} />
          </IconButton>
        </EndCell>
      </DataRow>
      <StyledRow open={open}>
        <DataCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginX={8} marginY={3}>
              <SessionDetailsSummary
                session={session}
                sectionCount={sectionCount}
                drillCount={drillCount}
                totalDuration={totalDuration}
              />
              <Box mt={4} display="flex" justifyContent="space-between">
                <LoaderButton
                  loading={false}
                  disabled={false}
                  onClick={handleViewSession}
                  variant="outlined"
                  color="primary"
                >
                  <Trans i18nKey={`${I18N_KEY}.seeSessionDetails`}>
                    See Session Details
                  </Trans>
                </LoaderButton>
                <LoaderButton
                  loading={false}
                  disabled={false}
                  onClick={handleAssignToPlayerClick}
                  variant="contained"
                  color="primary"
                >
                  <Trans i18nKey={`${I18N_KEY}.assignSchedule`}>
                    Assign Schedule
                  </Trans>
                </LoaderButton>
              </Box>
            </Box>
          </Collapse>
        </DataCell>
      </StyledRow>
    </>
  )
}

export default SessionRow
