import React, { useState, useEffect } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import { Routes } from 'src/utils/constants'
import { TabButton } from 'src/modules/common'
import { localizeRoutePath } from 'src/utils/i18n'
import { tabVariants, defaultTransition } from 'src/utils/animations'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'
import {
  getCoachDrill,
  getCoachDrills,
  selectedDrillSelector,
  updateDetailsDialogVisibility as updateDrillDetailsDialogVisibility,
} from 'src/store/drillsSlice'
import CoachDrillsTable from './drills/CoachDrillsTable'
import CoachSessionsTable from './sessions/CoachSessionsTable'
import { getCoachPlayers } from 'src/store/coachOverviewSlice'
import {
  getCoachSession,
  getCoachSessions,
  selectedSessionSelector,
} from 'src/store/sessionSlice'

const I18N_KEY = 'CoachPractice'

enum Tab {
  Drills = 'drills',
  Sessions = 'sessions',
}

const PracticeOverviewContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    background-color: ${theme.palette.background.xlight};
    padding: ${theme.spacing(8, 0, 0)};
  `
)

const Content = styled(MuiContainer)(
  ({ theme }) => css`
    flex: 1;

    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const LAYOUT_ID = 'practice-overview-buttons'

const CoachPractice: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const drillRouteMatch = useRouteMatch<{ drillUuid: string }>(
    localizeRoutePath(Routes.CoachPracticeDrillDetail)
  )

  const sessionRouteMatch = useRouteMatch<{ sessionUuid: string }>(
    localizeRoutePath(Routes.CoachPracticeSessions)
  )

  const selectInitialTab = () => {
    if (sessionRouteMatch) {
      return Tab.Sessions
    }
    return Tab.Drills
  }

  const initialTab = selectInitialTab()
  const [currentTab, setCurrentTab] = useState(initialTab)

  const showDrills = currentTab === Tab.Drills
  const showSessions = currentTab === Tab.Sessions

  const selectedDrill = useSelector(selectedDrillSelector)
  const selectedSession = useSelector(selectedSessionSelector)

  useEffect(() => {
    dispatch(getCoachDrills())
    dispatch(getCoachSessions())
    dispatch(getCoachPlayers())
  }, [dispatch])

  const handleShowDrills = () => {
    // const route = Routes.CoachPracticeDrills
    // history.push(route.replace(':playerUuid', playerUuid ?? ''))
    setCurrentTab(Tab.Drills)
  }

  const handleShowSessions = () => {
    // const route = Routes.CoachPracticeSessions
    // history.push(route.replace(':playerUuid', playerUuid ?? ''))
    setCurrentTab(Tab.Sessions)
  }

  const directToLocation = () => {
    const path = location.pathname.split(`${Routes.CoachPractice}/`)[1]
    switch (path) {
      case Tab.Drills:
        handleShowDrills()
        break
      case Tab.Sessions:
        handleShowSessions()
        break
    }
  }

  // TODO: Consider removing this logic and using query param to fetch within the dialogs themselves
  useEffect(() => {
    directToLocation()
    if (drillRouteMatch) {
      const { drillUuid } = drillRouteMatch.params
      if (!selectedDrill) {
        dispatch(getCoachDrill(drillUuid))
      }
      dispatch(
        updateDrillDetailsDialogVisibility({
          isOpen: true,
          uuid: drillUuid,
        })
      )
    } else if (sessionRouteMatch) {
      const { sessionUuid } = sessionRouteMatch.params
      if (!selectedSession) {
        dispatch(getCoachSession(sessionUuid))
      }
      // dispatch(
      //   updateSessionDetailsDialogVisibility({
      //     isOpen: true,
      //     uuid: sessionUuid,
      //   })
      // )
    } else {
      dispatch(
        showDrills
          ? updateDrillDetailsDialogVisibility({ isOpen: false })
          : null // updateSessionDetailsDialogVisibility({ isOpen: false })
      )
    }
  }, [dispatch, drillRouteMatch, sessionRouteMatch, location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PracticeOverviewContainer>
        <Content>
          <HeadingContainer>
            <Typography variant="h3" gutterBottom>
              <Trans i18nKey={`${I18N_KEY}.practice`}>Practice</Trans>
            </Typography>
            <AnimateSharedLayout>
              <Box>
                <TabButton selected={showDrills} onClick={handleShowDrills}>
                  <Trans i18nKey={`${I18N_KEY}.drills`}>Drills</Trans>
                  {showDrills && <ActiveBar layoutId={LAYOUT_ID} />}
                </TabButton>
                <TabButton selected={showSessions} onClick={handleShowSessions}>
                  <Trans i18nKey={`${I18N_KEY}.sessions`}>Sessions</Trans>
                  {showSessions && <ActiveBar layoutId={LAYOUT_ID} />}
                </TabButton>
              </Box>
            </AnimateSharedLayout>
          </HeadingContainer>
          <AnimatePresence initial={false} custom={showDrills} exitBeforeEnter>
            <motion.div
              exit="exit"
              initial="enter"
              animate="visible"
              custom={showDrills}
              variants={tabVariants}
              transition={defaultTransition}
              key={showDrills ? 'drills-table' : 'sessions-table'}
            >
              {showDrills && <CoachDrillsTable />}
              {showSessions && <CoachSessionsTable />}
            </motion.div>
          </AnimatePresence>
        </Content>
      </PracticeOverviewContainer>
    </>
  )
}

export default CoachPractice
